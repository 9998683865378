(function () {
    'use strict';
    angular
        .module('gwApp')
        .directive('crbtn', crbtn)
        .directive('ccbtn', ccbtn);

    function crbtn() {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                console.log(scope,element,attrs)
            }
        };
    }

    function ccbtn() {
        return {
            restrict: 'A',
            require: 'ngModal',
            link: function (scope, element, attrs,ngModel) {
                console.log(scope,element,attrs,ngModel)
            }
        };
    }
})();
