(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('PurchaseOrderController', PurchaseOrderController);
    PurchaseOrderController.$inject = ['$state', '$scope', 'PurchaseItemService', 'BaseProductCategoryService', 'BaseProductService', 'BaseCodeRuleService', 'BaseSaleService', 'BaseSupplierService', 'MessageService'];

    function PurchaseOrderController($state, $scope ,PurchaseItemService, BaseProductCategoryService, BaseProductService, BaseCodeRuleService, BaseSaleService, BaseSupplierService, MessageService) {
        /**
         * Created by vimpans on 2019/5/14
         */
        var vm = $scope;
        vm.purchaseOrderList = [];
        vm.seniorSearchMode = false;
        vm.seniorSearchShowing = {
            category: "全部",
            product: "全部",
            customer: "全部",
            customerId: null,
            supplier: "全部",
            supplierId: null,
            rule: "全部",
            ruleId: null,
            generateDateStartStatus:false,
            generateDateStart:null,
            generateDateStartEnd:false,
            generateDateEnd:null
        };

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };
        initDataAction();
        function initDataAction() {
            clearSeniorParams();
            PurchaseItemService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                sort:"id,desc",
                keyword: vm.pageparams.keyword
            },function (res) {
                vm.purchaseOrderList = res.data;
                vm.pageparams.count = res.info.count;
            });
        }
        vm.pageChangeAction = function () {
            if (vm.seniorSearchMode) {
                seniorSearchRequest();
            } else {
                initDataAction();
            }
        };
        vm.keyupAction = function (e) {
            if (e.keyCode === 13) {
                vm.searchAction();
            }
        };
        vm.searchAction = function () {
            vm.seniorSearchMode = false;
            vm.pageparams.page = 1;
            initDataAction();
        };

        //高级搜索参数
        vm.popoverStatus = false;
        vm.$watch("popoverStatus", function () {
            if (!vm.popoverStatus) {
                vm.vm.mutualIndex = 0;
            }
        });
        vm.mutualIndex = function (data,index,event){
            if (index === 3) {
                vm.seniorSearchShowing.supplierId = data.id;
            } else if (index === 4) {
                vm.seniorSearchShowing.customerId = data.id;
            } else if (index === 5) {
                vm.seniorSearchShowing.ruleId = data.id;
            }
        };
        vm.productCategoryList = [];
        vm.productCategorySearch = function (val) {
            BaseProductCategoryService.query({keyword:val},function (res) {
                vm.productCategoryList = res.data;
                vm.productCategoryList.unshift({number:"全部"});
            });
        };
        vm.productList = [];
        vm.productSearch = function (val) {
            var category = null;
            if (vm.seniorSearchShowing.category && vm.seniorSearchShowing.category!=="全部") {
                category = vm.seniorSearchShowing.category;
            }
            BaseProductService.queryAvailable({keyword:val,category:category},function (res) {
                vm.productList = res.data;
                vm.productList.unshift({number:"全部"});
            });
        };
        vm.supplierList = [];
        vm.searchSupplierAction = function (val) {
            BaseSupplierService.queryAvailable({keyword:val},function (res) {
                vm.supplierList = res.data;
                vm.supplierList.unshift({name:"全部"});
            });
        };
        vm.customerList = [];
        vm.searchCustomerAction = function (val) {
            BaseSaleService.queryAvailable({keyword: val}, function (res) {
                vm.customerList = res.data;
                vm.customerList.unshift({name:"全部"});
            });
        };
        vm.codeRoleList = [];
        vm.searchCodeRoleAction = function (val) {
            BaseCodeRuleService.queryAvailable({keyword: val}, function (res) {
                vm.codeRoleList = res.data;
                vm.codeRoleList.unshift({name:"全部"});
            });
        };

        vm.seniorString = "";
        vm.seniorSearchAction = function () {
            var param = seniorSearchParams();
            updateSeniorString();
            vm.seniorSearchMode = true;
            vm.pageparams.page = 1;
            vm.popoverStatus = false;
            seniorSearchRequest(param);
        };

        function seniorSearchParams() {
            var fields = [];
            var param = {documentType: "PurchaseItem", fields: fields};
            var generateDate = {
                field: "createdAt",
                type: "DATE",
                operator: "BETWEEN",
                value: null,
                value2: null
            };
            if (!vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = "2000-01-01T00:00:00.000Z";
                generateDate.value2 = vm.seniorSearchShowing.generateDateEnd.toEISOString();
                fields.push(generateDate);
            } else if (vm.seniorSearchShowing.generateDateStart && !vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = vm.seniorSearchShowing.generateDateStart.toSISOString();
                generateDate.value2 = "2100-01-01T23:59:59.000Z";
                fields.push(generateDate);
            } else if (vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = vm.seniorSearchShowing.generateDateStart.toSISOString();
                generateDate.value2 = vm.seniorSearchShowing.generateDateEnd.toEISOString();
                fields.push(generateDate);
            }

            if (vm.seniorSearchShowing.category !== "全部") {
                fields.push({
                    field: "batch.category",
                    type: "TEXT",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.category
                });
            }
            if (vm.seniorSearchShowing.product !== "全部") {
                fields.push({
                    field: "batch.product.number",
                    type: "TEXT",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.product
                });
            }
            if (vm.seniorSearchShowing.supplier !== "全部") {
                fields.push({
                    field: "batch.supplier.id",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.supplierId
                });
            }
            if (vm.seniorSearchShowing.rule !== "全部") {
                fields.push({
                    field: "batch.rule.id",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.ruleId
                });
            }
            if (vm.seniorSearchShowing.customer !== "全部") {
                fields.push({
                    field: "batch.deliveryItems.customerId",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.customerId
                });
            }
            return param;
        }

        vm.seniorRequestParams = null;
        function seniorSearchRequest(params) {
            clearPrimaryParams();
            if (params) {
                vm.seniorRequestParams = params;
            }
            var finalParams = angular.copy(vm.seniorRequestParams);
            finalParams.page = vm.pageparams.page-1;
            finalParams.size = vm.pageparams.size;
            finalParams.sort = "id,desc";
            PurchaseItemService.search(finalParams, function (res) {
                vm.purchaseOrderList = res.data;
                vm.pageparams.count = res.info.count;
            });
        }
        vm.seniorSearchCancel = function () {
            vm.popoverStatus = false;
        };
        function updateSeniorString() {
            var seniorString = "";
            seniorString += ("机器类别："+vm.seniorSearchShowing.category);
            seniorString += ("；货品序号："+vm.seniorSearchShowing.product);
            seniorString += ("；供应商："+vm.seniorSearchShowing.supplier);
            seniorString += ("；经销商："+vm.seniorSearchShowing.customer);
            seniorString += ("；码规则："+vm.seniorSearchShowing.rule);
            seniorString += "；操作时间：";
            var s = "全部";
            if (vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                s = vm.seniorSearchShowing.generateDateStart.toLocaleDateString()+"-"+vm.seniorSearchShowing.generateDateEnd.toLocaleDateString();
            } else if  (!vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                s = "早于"+vm.seniorSearchShowing.generateDateEnd.toLocaleDateString();
            } else if  (vm.seniorSearchShowing.generateDateStart && !vm.seniorSearchShowing.generateDateEnd) {
                s = "晚于"+vm.seniorSearchShowing.generateDateStart.toLocaleDateString();
            }
            seniorString += s;
            vm.seniorString = seniorString;
        }

        function clearSeniorParams() {
            vm.seniorSearchShowing = {
                category: "全部",
                product: "全部",
                customer: "全部",
                customerId: null,
                supplier: "全部",
                supplierId: null,
                rule: "全部",
                ruleId: null,
                generateDateStartStatus:false,
                generateDateStart:null,
                generateDateStartEnd:false,
                generateDateEnd:null
            };
        }
        function clearPrimaryParams() {
            vm.pageparams.keyword = "";
        }

        vm.downloading = false;
        vm.exportExcel = function () {
            if (vm.downloading) {
                MessageService.error('下载中，请勿重复操作!');
                return;
            }
            vm.downloading = true;
            var params = seniorSearchParams();
            if (params) {
                vm.seniorRequestParams = params;
            }
            var finalParams = angular.copy(vm.seniorRequestParams);
            finalParams.page = vm.pageparams.page-1;
            finalParams.size = vm.pageparams.size;
            finalParams.sort = "id,desc";
            PurchaseItemService.download(finalParams, function (res) {
                MessageService.success('下载中...');
                console.log('download action success:', res);
                download(res.blob, decodeURIComponent(res.filename));
                vm.downloading = false;
            }, function (error) {
                if (error.data && error.data.blob && error.data.blob.type === 'application/json') {
                    var data = error.data.blob;
                    var reader = new FileReader();
                    reader.onload = function (ev) {
                        var jsonString = ev.target.result;
                        var json = JSON.parse(jsonString);
                        console.log('download action fail:', json);
                        MessageService.error('下载出错，'+((json.message+'，')||'')+'请重试!');
                    };
                    reader.readAsText(data,'UTF-8');
                } else {
                    MessageService.error('下载出错,请重试!');
                }
                vm.downloading = false;
            });
        };
        function download(blob,fileName) {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            //TODO 火狐浏览器兼容
            // document.body.appendChild(a);
            a.click();
            //TODO 火狐浏览器兼容
            // document.body.removeChild(a);
        }
    }
})();
