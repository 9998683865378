(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeCancelModalController', CodeCancelModalController);
    CodeCancelModalController.$inject = ['$state', '$scope', 'entity', '$uibModalInstance', 'MessageService', 'CodeBatchService'];

    function CodeCancelModalController($state, $scope, entity, $uibModalInstance, MessageService, CodeBatchService) {
        /**
         * Created by vimpans on 2019/5/10
         */
        var vm = $scope;
        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.level = entity.level;
        vm.childCount = entity.childCount;
        vm.cascade = false;

        vm.cancelModel = {
            codeId: entity.id,
            reason: null
        };

        vm.saveAction = function () {
            vm.cancelModel.cascade = vm.cascade;
            CodeBatchService.cancelCode(vm.cancelModel, function (res) {
                MessageService.success('作废单码成功 !');
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error('作废单码失败 !' + (error.title||''));
            });
        }
    }
})();
