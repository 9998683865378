(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('LoginUserController', LoginUserController);

    LoginUserController.$inject = ['$rootScope', '$state', '$log', 'Role', 'Account', 'Upload', 'Principal', 'MessageService'];

    function LoginUserController ($rootScope, $state, $log, Role, Account, Upload, Principal, MessageService) {
        var vm = this;
        vm.upload = upload;

        vm.dateOptions = {
            dateDisabled: disabled
        };

        // Disable weekend selection
        function disabled(data) {
            var date = data.date,
                mode = data.mode;
            return mode === 'day' && (date > new Date());
        }

        vm.pagingParams = {
            ascending:true,
            page:1,
            predicate:"id",
            sort:"id,asc",
            itemsPerPage:10
        };
        vm.itemsPerPage = vm.pagingParams.itemsPerPage;
        vm.predicate = vm.pagingParams.predicate;
        vm.reverse = vm.pagingParams.ascending;
        vm.page = vm.pagingParams.page;

        vm.user = {};   // user entity
        vm.gender = [
            {name:"保密", code:"0"},
            {name:"男", code:"1"},
            {name:"女", code:"2"}
        ];
        function getGenderByCode(code) {
            for (var i = vm.gender.length - 1; i >= 0; i--) {
                if(vm.gender[i].code == code) {
                    return vm.gender[i];
                }
            }

            return null;
        }

        /* 角色搜索框 */
        vm.searchRole = {
            placeholder: '输入搜索角色',
            allowClear: true,
            query: function(query) {
                Role.query({
                    keyword: query.term,
                    enabled: "onlyEnabled",
                    page: query.page - 1,
                    size: 10,
                    sort: "id,desc"
                }, function(data) {
                    if (data && data.length > 0) {
                        for (var i = 0; data && i < data.length; i++) {
                            var it = data[i];
                        }
                        query.callback({
                            text: function(it) {
                                return it.name || '';
                            },
                            more: data.length > 0,
                            results: data
                        });
                    } else {
                        query.callback({
                            results: []
                        });
                    }
                }, function(data) {
                    query.callback({
                        results: []
                    });
                });
            },
            initSelection: function(element, callback) {
                return element.name;
            },
            formatResult: entityFormat,
            formatSelection: entityFormat
        };
        function entityFormat(it) {
            return it.name || '';
        }

        // 加载当前平台登陆用户信息
        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                $log.debug(account.data);
                vm.user = account.data;
                if(vm.user.imageUrl==""){
                    vm.user.imageUrl = null;
                }
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.imageUrl = vm.user.imageUrl;
                // 加载Role
                // loadRoleAll();// 加载Role
                Role.get({
                    id: vm.user.roleId
                },function(data){
                    vm.user.role = data;
                    $log.debug(data);
                });

                // 转换数据显示
                vm.genderSelected = getGenderByCode(vm.user.gender);
                vm.user.dateOfBirth = isEmpty(vm.user.dateOfBirth) ? null : new Date(vm.user.dateOfBirth);
            });
        }

        vm.datePickerOpenStatus = false;
        vm.openCalendar = openCalendar;
        function openCalendar(){
            vm.datePickerOpenStatus = true;
        }

        // 修改提交
        vm.update = update;
        function update () {
            if(vm.user.role == null) {
                MessageService.error("请选择角色");
                return;
            }

            // 初始化数据
            vm.user.roleId = vm.user.role.id;
            vm.user.roleName = vm.user.role.name;
            vm.user.gender = vm.genderSelected.code;

            validate();
        }

        vm.validateEmail = validateEmail;
        function validateEmail(){
            if(isEmpty(vm.user.email)) {
                return;
            }

            vm.errorEmail = isEmpty(vm.user.email.match("^[a-zA-Z0-9_-]\.+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$"));
        }

        vm.validatePhone = validatePhone;
        function validatePhone(){
            if(isEmpty(vm.user.phone)) {
                return;
            }

            vm.errorPhone = isEmpty(vm.user.phone.match("^[0-9-]{11}$"));
        }

        function validate(){
            var file = document.querySelector('input[type=file]').files[0];
            if(file!=null){
                Account.update(vm.user, onSaveSuccess, onSaveError);
            }else{
                Account.update(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            MessageService.success("信息编辑成功");
            $rootScope.$broadcast('changeUserLogo', vm.user.imageUrl);

            $state.reload();
        }

        function onSaveError () {
            MessageService.error("请检查登录名和邮箱是否已被使用");
        }

        function isEmpty(target) {
            return (target === '') || (target === null) || (target === undefined);
        }

        function upload(){
            var file = document.querySelector('input[type=file]').files[0];
            Upload.upload({
                url: 'qr/api/files/user/upload',
                data: {file: file}
            }).then(function (resp) {
                console.log('resp',resp);
                // vm.user.imageUrl = resp.data.path;
                //qr/api/files?accessPath=
                vm.imageUrl = vm.user.imageUrl = "qr/api/files?accessPath="+resp.data.data.path;
            });
        }
    }
})();
