(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeController', CodeController);
    CodeController.$inject = ['$state', '$scope', '$uibModal', 'CodeBatchService', 'MessageService', '$uibTooltip', '$templateCache', 'BaseProductService', 'BaseProductCategoryService', 'BaseSupplierService', 'BaseSaleService', 'BaseCodeRuleService', '$stateParams', 'SmartDocService'];

    function CodeController($state, $scope, $uibModal, CodeBatchService, MessageService, $uibTooltip, $templateCache, BaseProductService, BaseProductCategoryService, BaseSupplierService, BaseSaleService, BaseCodeRuleService, $stateParams, SmartDocService) {
        /**
         * Created by vimpans on 2019/5/7
         */
        var vm = $scope;
        vm.codeList = [];
        vm.seniorSearchMode = false;
        vm.seniorSearchShowing = {
            category: "全部",
            level: "全部",
            product: "全部",
            customer: "全部",
            customerId: null,
            supplier: "全部",
            supplierId: null,
            rule: "全部",
            ruleId: null,
            generateDateStartStatus:false,
            generateDateStart:null,
            generateDateStartEnd:false,
            generateDateEnd:null,
            generate: false,
            download: false,
            inboard: false,
            outboard: false,
            active: false,
            stack :false,
            cancel: false
        };
        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };

        if ($stateParams.keyword) {
            vm.pageparams.keyword = $stateParams.keyword;
        }

        vm.inPage = true;
        vm.cachedFields = null;
        vm.showSaveToMenu = false;

        var stateP = $stateParams.id || "";
        if (stateP !== "") {
            //自动进去高级搜索模式
            vm.seniorId = parseInt(stateP);
            vm.seniorSearchMode = true;
            SmartDocService.findOne({id: vm.seniorId}, function (res) {
                //参数还原
                var fields = (res.data||{fields:[]}).fields;
                vm.cachedFields = copyFields(fields);

                for (var i=0; i<fields.length; i++) {
                    var field = fields[i];
                    if (field.field === "category" && field.value) {
                        vm.seniorSearchShowing.category = field.value;
                    }
                    if (field.field === "rule.level" && field.value) {
                        vm.seniorSearchShowing.level = field.valueTitle;
                    }
                    if (field.field === "rule.id" && field.value) {
                        vm.seniorSearchShowing.ruleId = field.value;
                        vm.seniorSearchShowing.rule = field.valueTitle;
                    }
                    if (field.field === "deliveryItems.customerId" && field.value) {
                        vm.seniorSearchShowing.customerId = field.value;
                        vm.seniorSearchShowing.customer = field.valueTitle;
                    }
                    if (field.field === "supplier.id" && field.value) {
                        vm.seniorSearchShowing.supplierId = field.value;
                        vm.seniorSearchShowing.supplier = field.valueTitle;
                    }
                    if (field.field === "product.number" && field.value) {
                        vm.seniorSearchShowing.product = field.value;
                    }
                    if (field.field === "createdDate") {
                        if (field.value && field.value!=0) {
                            vm.seniorSearchShowing.generateDateStart = new Date(field.value);
                        }
                        if (field.value2 && field.value2!=0) {
                            vm.seniorSearchShowing.generateDateEnd = new Date(field.value2);
                        }
                    }
                    if (field.field === "generated") {
                        vm.seniorSearchShowing.generate = true;
                    }
                    if (field.field === "download") {
                        vm.seniorSearchShowing.download = true;
                    }
                    if (field.field === "inboard") {
                        vm.seniorSearchShowing.inboard = true;
                    }
                    if (field.field === "outboard") {
                        vm.seniorSearchShowing.outboard = true;
                    }
                    if (field.field === "activated") {
                        vm.seniorSearchShowing.active = true;
                    }
                    if (field.field === "stacking") {
                        vm.seniorSearchShowing.stack = true;
                    }
                    if (field.field === "cancelled") {
                        vm.seniorSearchShowing.cancel = true;
                    }
                }
                vm.seniorSearchAction();
            });
        } else {
            initDataAction();
        }
        // initDataAction();
        function initDataAction() {
            clearSeniorParams();
            CodeBatchService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                sort:"id,desc",
                keyword: vm.pageparams.keyword
            }, function (res) {
                vm.codeList = res.data.map(function (val) {
                    if (val.year && val.week) {
                        val.w = (parseInt(val.year)-17) + '年' + val.week + '周';
                    }
                    else {
                        val.w = "";
                    }
                    var showString = '';
                    if (val.activated && val.status === "FINISHED") {
                        showString = '已激活：'+val.activatedQuantity;
                    } else if (val.outboard && val.status === "FINISHED") {
                        showString = '已出库：'+val.inboardQuantity;
                    } else if (val.inboard && val.status === "FINISHED") {
                        showString = '已入库：'+val.outboardQuantity;
                    } else if (val.stacking && val.status === "FINISHED") {
                        showString = '已堆垛：'+val.stackingQuantity;
                    } else if (val.generated && val.status === "FINISHED") {
                        showString = '已生成：'+val.generatedQuantity;
                    } else {
                        showString = '生成中：'+val.generatedQuantity;
                    }
                    val.showString = showString;
                    return val;
                });

                vm.pageparams.count = res.info.count;

                var temp = vm.codeList.filter(function (value) {
                    return (value.status !== "FINISHED") && !value.error;
                });
                if (temp.length > 0 && vm.inPage) {
                    setTimeout(function () {
                        initDataAction();
                    },2000)
                }
                vm.pageparams.count = res.info.count;
            });
        }

        $scope.$on('$stateChangeStart',function(){
            vm.inPage = false;
        });

        vm.pageChangeAction = function () {
            if (vm.seniorSearchMode) {
                seniorSearchRequest();
            } else {
                initDataAction();
            }
        };
        vm.keyupAction = function (e) {
            if (e.keyCode === 13) {
                vm.searchAction();
            }
        };
        vm.searchAction = function () {
            vm.pageparams.page = 1;
            vm.seniorSearchMode = false;
            initDataAction();
        };

        vm.createCodeModal = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/code/code-create.html',
                controller: 'CodeCreateController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {
                if (vm.seniorSearchMode) {
                    seniorSearchRequest();
                } else {
                    initDataAction();
                }
            });
        };
        vm.editCodeModal = function (id,check) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/code/code-edit.html',
                controller: 'CodeEditController',
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    entity: function () {
                        return {id:id,check:check};
                    }
                }
            }).result.then(function () {
                if (vm.seniorSearchMode) {
                    seniorSearchRequest();
                } else {
                    initDataAction();
                }
            },function () {
                if (vm.seniorSearchMode) {
                    seniorSearchRequest();
                } else {
                    initDataAction();
                }
            });
        };
        vm.downloadCodeModal = function (id) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/code/code-download.html',
                controller: 'CodeDownloadController',
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    entity: function () {
                        return {id: id};
                    }
                }
            }).result.then(function () {
                if (vm.seniorSearchMode) {
                    seniorSearchRequest();
                } else {
                    initDataAction();
                }
            });
        };
        vm.checkCodeModal = function (id) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/code/code-check.html',
                controller: 'CodeCheckController',
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    entity: function () {
                        return {id: id};
                    }
                }
            }).result.then(function () {

            },function () {
                if (vm.seniorSearchMode) {
                    seniorSearchRequest();
                } else {
                    initDataAction();
                }
            });
        };
        vm.cancelErrorAction = function (id) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/code/code-delete-modal.html',
                controller: 'CodeDeleteModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {id: id};
                    }
                }
            }).result.then(function () {
                if (vm.seniorSearchMode) {
                    seniorSearchRequest();
                } else {
                    initDataAction();
                }
            });
        };
        vm.reActiveErrorAction = function (id) {
            CodeBatchService.retryGenerate({id:id},function (res) {
                if (vm.seniorSearchMode) {
                    seniorSearchRequest();
                } else {
                    initDataAction();
                }
            },function (error) {
                MessageService.error('重试失败 ！'+(error.title||''));
            });
        };




        //高级搜索参数
        vm.popoverStatus = false;
        vm.$watch("popoverStatus", function () {
            if (!vm.popoverStatus) {
                vm.vm.mutualIndex = 0;
            }
        });
        vm.mutualIndex = function (data,index,event){
            if (index === 3) {
                vm.seniorSearchShowing.supplierId = data.id;
            } else if (index === 4) {
                vm.seniorSearchShowing.customerId = data.id;
            } else if (index === 5) {
                vm.seniorSearchShowing.ruleId = data.id;
            }
        };
        vm.codeLevelModel = [
            {name:"全部",value:null},
            {name:"产品码",value:0},
            {name:"垛码",value:1}
        ];
        vm.productCategoryList = [];
        vm.productCategorySearch = function (val) {
            BaseProductCategoryService.query({keyword:val},function (res) {
                vm.productCategoryList = res.data;
                vm.productCategoryList.unshift({number:"全部"});
            });
        };
        vm.productList = [];
        vm.productSearch = function (val) {
            var category = null;
            if (vm.seniorSearchShowing.category && vm.seniorSearchShowing.category!=="全部") {
                category = vm.seniorSearchShowing.category;
            }
            BaseProductService.queryAvailable({keyword:val,category:category},function (res) {
                vm.productList = res.data;
                vm.productList.unshift({number:"全部"});
            });
        };
        vm.supplierList = [];
        vm.searchSupplierAction = function (val) {
            BaseSupplierService.queryAvailable({keyword:val},function (res) {
                vm.supplierList = res.data;
                vm.supplierList.unshift({name:"全部"});
            });
        };
        vm.customerList = [];
        vm.searchCustomerAction = function (val) {
            BaseSaleService.queryAvailable({keyword: val}, function (res) {
                vm.customerList = res.data;
                vm.customerList.unshift({name:"全部"});
            });
        };
        vm.codeRoleList = [];
        function translateLevel() {
            if (vm.seniorSearchShowing.level === "产品码") {
                return 0;
            } else if (vm.seniorSearchShowing.level === "垛码") {
                return 1;
            } else {
                return null;
            }
        }
        vm.searchCodeRoleAction = function (val) {
            BaseCodeRuleService.queryAvailable({keyword: val, level: translateLevel()}, function (res) {
                vm.codeRoleList = res.data;
                vm.codeRoleList.unshift({name:"全部"});
            });
        };
        vm.seniorString = "";
        vm.seniorSearchAction = function () {
            var fields = [];
            var param = {documentType: "CodeBatch", fields: fields};
            if (vm.seniorSearchShowing.level !== "全部") {
                fields.push({
                    field: "rule.level",
                    type: "INT",
                    operator: "EQ",
                    value: translateLevel()
                });
            }

            var generateDate = {
                field: "createdDate",
                type: "DATE",
                operator: "BETWEEN",
                value: null,
                value2: null
            };
            if (!vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = "2000-01-01T00:00:00.000Z";
                generateDate.value2 = vm.seniorSearchShowing.generateDateEnd.toEISOString();
                fields.push(generateDate);
            } else if (vm.seniorSearchShowing.generateDateStart && !vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = vm.seniorSearchShowing.generateDateStart.toSISOString();
                generateDate.value2 = "2100-01-01T23:59:59.000Z";
                fields.push(generateDate);
            } else if (vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = vm.seniorSearchShowing.generateDateStart.toSISOString();
                generateDate.value2 = vm.seniorSearchShowing.generateDateEnd.toEISOString();
                fields.push(generateDate);
            }

            if (vm.seniorSearchShowing.category !== "全部") {
                fields.push({
                    field: "category",
                    type: "TEXT",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.category
                });
            }
            if (vm.seniorSearchShowing.product !== "全部") {
                fields.push({
                    field: "product.number",
                    type: "TEXT",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.product
                });
            }
            if (vm.seniorSearchShowing.supplier !== "全部") {
                fields.push({
                    field: "supplier.id",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.supplierId
                });
            }
            if (vm.seniorSearchShowing.rule !== "全部") {
                fields.push({
                    field: "rule.id",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.ruleId
                });
            }
            if (vm.seniorSearchShowing.customer !== "全部") {
                fields.push({
                    field: "deliveryItems.customerId",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.customerId
                });
            }
            if (vm.seniorSearchShowing.generate) {
                fields.push({
                    field: "generated",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
            }
            if (vm.seniorSearchShowing.download) {
                fields.push({
                    field: "download",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
            }
            if (vm.seniorSearchShowing.inboard) {
                fields.push({
                    field: "inboard",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
            }
            if (vm.seniorSearchShowing.outboard) {
                fields.push({
                    field: "outboard",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
            }
            if (vm.seniorSearchShowing.active) {
                fields.push({
                    field: "activated",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
            }
            if (vm.seniorSearchShowing.stack) {
                fields.push({
                    field: "stacking",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
            }
            if (vm.seniorSearchShowing.cancel) {
                fields.push({
                    field: "cancelled",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
            }
            updateSeniorString();
            vm.seniorSearchMode = true;
            vm.pageparams.page = 1;
            vm.popoverStatus = false;
            seniorSearchRequest(param);
            if (checkConditionsBeenChanged(fields)) {
                vm.showSaveToMenu = true;
            }
            else {
                vm.showSaveToMenu = false;
            }
        };
        function copyFields(fields) {
            var copy = [];
            if (!fields || fields.length == 0) {
                return copy;
            }
            for (var i = 0; i < fields.length; i++) {
                var f = fields[i];
                if (!f.field || !f.operator || !f.type) {
                    continue;
                }
                if (f.operator == 'BETWEEN') {
                    if (f.value == null && f.value2 == null) {
                        continue;
                    }
                    if (f.value == '0' && f.value2 == '0') {
                        continue;
                    }
                }
                else if (f.value == null) {
                    continue;
                }
                var cache = {
                    field: f.field,
                    type: f.type,
                    operator: f.operator,
                    value: f.value,
                    value2: f.value2
                };
                copy.push(cache);

            }
            return copy;
        }
        function checkConditionsBeenChanged(fields) {
            if (!fields || fields.length == 0) {
                return false;
            }
            if (!vm.cachedFields || vm.cachedFields.length == 0) {
                return true;
            }
            if (vm.cachedFields.length != fields.length) {
                return true;
            }
            for (var i = 0; i < fields.length; i++) {
                var found = false;
                var changed = false;
                var f = fields[i];
                for (var j = 0; j < vm.cachedFields.length; j++) {
                    var cf = vm.cachedFields[j];
                    if (f.field == cf.field) {
                        found = true;
                        if (f.operator != cf.operator || f.value != cf.value) {
                            changed = true;
                        }
                        if (f.operator == 'BETWEEN') {
                            if (!f.value != cf.value ||  f.value2 != cf.value2) {
                                changed = true;
                            }
                        }
                    }
                    if (!found || changed) {
                        return true;
                    }
                }
            }
            return false;
        }
        vm.seniorRequestParams = null;
        function seniorSearchRequest(params) {
            clearPrimaryParams();
            if (params) {
                vm.seniorRequestParams = params;
            }
            var finalParams = angular.copy(vm.seniorRequestParams);
            finalParams.page = vm.pageparams.page-1;
            finalParams.size = vm.pageparams.size;
            finalParams.sort = "id,desc";
            CodeBatchService.search(finalParams, function (res) {
                vm.codeList = res.data.map(function (val) {
                    if (val.year && val.week) {
                        val.w = (parseInt(val.year)-17) + '年' + val.week + '周';
                    }
                    else {
                        val.w = "";
                    }
                    var showString = '';
                    if (val.activated && val.status === "FINISHED") {
                        showString = '已激活：'+val.activatedQuantity;
                    } else if (val.outboard && val.status === "FINISHED") {
                        showString = '已出库：'+val.inboardQuantity;
                    } else if (val.inboard && val.status === "FINISHED") {
                        showString = '已入库：'+val.outboardQuantity;
                    } else if (val.stacking && val.status === "FINISHED") {
                        showString = '已堆垛：'+val.stackingQuantity;
                    } else if (val.generated && val.status === "FINISHED") {
                        showString = '已生成：'+val.generatedQuantity;
                    } else {
                        showString = '生成中：'+val.generatedQuantity;
                    }
                    val.showString = showString;
                    return val;
                });

                vm.pageparams.count = res.info.count;

                var temp = vm.codeList.filter(function (value) {
                    return (value.status !== "FINISHED") && !value.error;
                });
                if (temp.length > 0 && vm.inPage) {
                    setTimeout(function () {
                        seniorSearchRequest();
                    },2000)
                }
                vm.pageparams.count = res.info.count;
            });
        }
        vm.saveSeniorAction = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/code/code-senior-search.html',
                controller: 'CodeSeniorSearchController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return angular.copy(vm.seniorSearchShowing);
                    }
                }
            }).result.then(function () {

            });
        };
        vm.seniorSearchCancel = function () {
            vm.popoverStatus = false;
        };
        function updateSeniorString() {
            var seniorString = "";
            seniorString = "码等级："+vm.seniorSearchShowing.level+"；生码时间：";
            var s = "全部";
            if (vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                s = vm.seniorSearchShowing.generateDateStart.toLocaleDateString()+"-"+vm.seniorSearchShowing.generateDateEnd.toLocaleDateString();
            } else if  (!vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                s = "早于"+vm.seniorSearchShowing.generateDateEnd.toLocaleDateString();
            } else if  (vm.seniorSearchShowing.generateDateStart && !vm.seniorSearchShowing.generateDateEnd) {
                s = "晚于"+vm.seniorSearchShowing.generateDateStart.toLocaleDateString();
            }
            seniorString += s;
            seniorString += ("；机器类别："+vm.seniorSearchShowing.category);
            seniorString += ("；货品序号："+vm.seniorSearchShowing.product);
            seniorString += ("；供应商："+vm.seniorSearchShowing.supplier);
            seniorString += ("；经销商："+vm.seniorSearchShowing.customer);
            seniorString += ("；码规则："+vm.seniorSearchShowing.rule);
            var statusList = [];
            if (vm.seniorSearchShowing.generate) {statusList.push("已生成");}
            if (vm.seniorSearchShowing.download) {statusList.push("已下载");}
            if (vm.seniorSearchShowing.inboard) {statusList.push("已入库>1%");}
            if (vm.seniorSearchShowing.outboard) {statusList.push("已出库>1%");}
            if (vm.seniorSearchShowing.active) {statusList.push("已激活>1%");}
            if (vm.seniorSearchShowing.stack) {statusList.push("已堆垛>1%");}
            if (vm.seniorSearchShowing.cancel) {statusList.push("已作废>1%");}
            if (statusList.length === 0) {
                seniorString += "；码状态：全部";
            } else {
                seniorString += ("；码状态："+statusList.join("、"));
            }
            vm.seniorString = seniorString;
        }

        function clearSeniorParams() {
            vm.seniorSearchShowing = {
                category: "全部",
                level: "全部",
                product: "全部",
                customer: "全部",
                customerId: null,
                supplier: "全部",
                supplierId: null,
                rule: "全部",
                ruleId: null,
                generateDateStartStatus:false,
                generateDateStart:null,
                generateDateStartEnd:false,
                generateDateEnd:null,
                generate: false,
                download: false,
                inboard: false,
                outboard: false,
                active: false,
                stack :false,
                cancel: false
            };
        }
        function clearPrimaryParams() {
            vm.pageparams.keyword = "";
        }
    }
})();
