(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ModuleResetPasswordController', ModuleResetPasswordController);

    ModuleResetPasswordController.$inject = ['Auth', 'Principal','$uibModalInstance','User','$state','Password','MessageService'];

    function ModuleResetPasswordController (Auth, Principal,$uibModalInstance,User,$state,Password,MessageService) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.cancel = cancel;
        vm.success = null;
        vm.newPwdEntry = true;
        vm.confirmPwdEntry = true;
        vm.oldpwdEntry = true;
        vm.oldPassword = null;
        vm.newPassword = null;
        vm.confirmPassword = null;

        Principal.identity().then(function(account) {
            vm.account = account;
            vm.user = account;
        });

        vm.postData = {
            password: null
        };

        function changePassword () {

            if (!vm.oldPassword) {
                MessageService.error("请输入原密码");
                return;
            }
            if (!vm.newPassword) {
                MessageService.error("请输入新密码");
                return;
            }
            if (vm.newPassword.length < 8 || vm.newPassword.leading > 32) {
                MessageService.error("密码长度应在8 ~ 32位之间 ！");
                return;
            }
            if (!vm.confirmPassword) {
                MessageService.error("请确认新密码");
                return;
            }
            if (vm.confirmPassword !== vm.newPassword) {
                MessageService.error("两次输入密码不一致");
                return;
            }

            var postData = {
                id:vm.user.data.id,
                password:vm.newPassword,
                oldPassword:vm.oldPassword
            };
            User.reset(postData,function(data){
                if(data.errorInfo){
                    MessageService.error(data.errorInfo);
                    vm.oldPassword = "";
                }else{
                    MessageService.success("密码重置成功");
                    $state.go("login");
                    Auth.logout();
                    $uibModalInstance.close();
                }
            }, function (data) {
                MessageService.error("重置密码失败");
            });
        }

        function cancel (){
            window.history.back();
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
