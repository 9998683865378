(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('PurchaseOrderService', PurchaseOrderService);

    PurchaseOrderService.$inject = ['$resource'];

    function PurchaseOrderService($resource) {
        var service = $resource('qr/api/purchaseOrders/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false}
        });
        return service;
    }
})();
