(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeRuleCreateController', CodeRuleCreateController);
    CodeRuleCreateController.$inject = ['$state', '$scope', 'entity', '$uibModalInstance', 'BaseCodeRuleService', 'ProductCategoryService', 'MessageService', 'BaseProductService', 'BaseBatchNOService', 'BaseProductCategoryService'];

    function CodeRuleCreateController($state, $scope, entity, $uibModalInstance, BaseCodeRuleService, ProductCategoryService, MessageService, BaseProductService, BaseBatchNOService, BaseProductCategoryService) {
        /**
         * Created by vimpans on 2019/4/30
         */
        var vm = $scope;
        if (entity && entity.check) {
            vm.forbiddenEdit = true;
        }

        initDataAction();
        function initDataAction() {
            vm.codeCategory = [];
            BaseCodeRuleService.segmentTypes({},function (res) {
                vm.codeCategory = res.data.map(function (value) {
                    value.options = [];
                    return value;
                });
            });
            vm.batchDefaultList = [];
            BaseBatchNOService.query({},function (res) {
                vm.batchDefaultList = res.data.available;
            });
        }

        vm.productList = [];
        vm.productSearch = function (val) {
            var category = null;
            for (var i=0; i<vm.codeInfoList.length; i++) {
                var info = vm.codeInfoList[i];
                if (info.valueType === 'ALPHABET_CATEGORY') {
                    category = info.value;
                }
            }
            BaseProductService.queryAvailable({keyword:val,category:category},function (res) {
                vm.productList = res.data;
            });
        };
        vm.productCategoryList = [];
        vm.productCategorySearch = function (val) {
            BaseProductCategoryService.query({keyword:val},function (res) {
                vm.productCategoryList = res.data;
            });
        };

        vm.codeRuleEntity = {
            level: 0,
            levelName: '产品码',
            barcode: true,
            qrcode: false,
            barcodeType: 'CODE128A',
            qrTolerantLevel: 'Q',
            unit: '对',
            qrDomainUrlEnabled: true,
            urlPrefix: 'https://',
            urlAddress: null
        };
        vm.codeLevelList = [{name:'产品码',value:'0'},{name:'垛码',value:'1'}];
        vm.barCodeType = ['CODE128A','CODE128B','CODE128C'];
        vm.qrCodeLevel = ['H','Q','M','L'];
        // {name:null,type:null,valueType:null,size:null,value:null,description:null}
        vm.codeInfoList = [{name:null,typeIndex:null,typeName:null,valueTypeIndex:null,valueTypeName:null,valueType:null,size:null,value:null,description:null}];
        vm.codeSampleAction = function () {
            var temp = vm.codeInfoList.map(function (val) {
                return val.value||'';
            });
            return temp.join('');
        };

        vm.isEdit = false;
        if (entity && entity.id) {
            vm.isEdit = true;
            BaseCodeRuleService.get({id:entity.id},function (res) {
                vm.codeRuleEntity = res.data;
                var l = vm.codeLevelList.filter(function (val) {
                    return val.value==vm.codeRuleEntity.level;
                });
                if (l && l.length>0) {
                    vm.codeRuleEntity.levelName = l[0].name;
                }
                var domain = vm.codeRuleEntity.qrDomainUrl.split('//');
                vm.codeRuleEntity.urlPrefix = domain[0]+'//';
                vm.codeRuleEntity.urlAddress = domain[1];

                vm.codeInfoList = vm.codeRuleEntity.segments;
                for (var i=0; i<vm.codeInfoList.length; i++) {
                    var codeInfo = vm.codeInfoList[i];
                    var t = typePosition(codeInfo.type);
                    var type = vm.codeCategory[t];
                    codeInfo.typeIndex = t;
                    codeInfo.typeName = type.name;
                    valueTypePosition(i);
                }
                if (vm.codeRuleEntity.id && vm.codeRuleEntity.batchCounter!==0 && !vm.forbiddenEdit) {
                    vm.codeRuleEntity.id = null;
                    checkField(vm.codeRuleEntity.name);
                }
            });
        }

        function typePosition(type) {
            var index = null;
            for (var i=0; i<vm.codeCategory.length; i++) {
                if (vm.codeCategory[i].value === type) {
                    index = i;
                }
            }
            return index;
        }
        function valueTypePosition(index) {
            var info = vm.codeInfoList[index];
            var cc = vm.codeCategory[info.typeIndex];
            if (!cc.options || cc.options.length===0) {
                BaseCodeRuleService.segmentOptions({type:cc.value},function (res) {
                    cc.options = res.data;
                    for (var i=0; i<cc.options.length; i++) {
                        var valueType = cc.options[i];
                        if (valueType.key === info.valueType) {
                            info.valueType = valueType.key;
                            info.valueTypeIndex = i;
                            info.valueTypeName = valueType.name;
                        }
                    }
                });
            } else {
                for (var i=0; i<cc.options.length; i++) {
                    var valueType = cc.options[i];
                    if (valueType.key === info.valueType) {
                        info.valueType = valueType.key;
                        info.valueTypeIndex = i;
                        info.valueTypeName = valueType.name;
                    }
                }
            }
        }

        vm.cancelAction = function () {
            $uibModalInstance.dismiss();
        };
        // vm.dragAction = {
        //     orderChanged: function (a,b,c) {
        //         console.log(codeInfoList)
        //     }
        // };

        requestProductCategory();
        function requestProductCategory() {
            ProductCategoryService.query({},function (res) {

            });
        }

        vm.selectCodeLevel = function (idex) {
            vm.codeRuleEntity.level = vm.codeLevelList[idex].value;
            vm.codeRuleEntity.levelName = vm.codeLevelList[idex].name;
        };
        //rule segment table
        vm.minusAtIndex = function (idex) {
            vm.codeInfoList.splice(idex,1);
        };
        vm.plusAction = function () {
            vm.codeInfoList.push({name:null,typeIndex:null,typeName:null,valueTypeIndex:null,valueTypeName:null,valueType:null,size:null,value:null,description:null});
        };
        vm.codeCategorySel = function (cc) {
            if (!cc.options || cc.options.length===0) {
                BaseCodeRuleService.segmentOptions({type:cc.value},function (res) {
                    cc.options = res.data;
                });
            }
        };

        vm.saveAction = function () {
            if (vm.nameExists) {
                return;
            }

            var temp = [];
            var SERIALCount = 0;
            var DIGITS_PRODUCTcount = 0;
            var ALPHABET_CATEGORYcount = 0;
            var ALPHABET_BATCHcount = 0;
            for (var i=0; i<vm.codeInfoList.length; i++) {
                var value = vm.codeInfoList[i];
                if (hasContent(value.name) &&
                    hasContent(value.typeIndex) &&
                    hasContent(value.valueTypeIndex) &&
                    hasContent(value.value)) {
                    var t = vm.codeCategory[value.typeIndex].value;
                    var o = vm.codeCategory[value.typeIndex].options[value.valueTypeIndex];
                    var regex = new RegExp("^[" + o.source + "]+$","g");
                    if (!regex.test(value.value)) {
                        MessageService.error('码段信息输入错误，'+o.name+'的默认样例输入错误 ！');
                        return;
                    }
                    if (t === 'SERIAL') {
                        SERIALCount++;
                    }

                    if (o.key === 'DIGITS_PRODUCT') {DIGITS_PRODUCTcount ++}
                    if (o.key === 'ALPHABET_CATEGORY') {ALPHABET_CATEGORYcount ++}
                    if (o.key === 'ALPHABET_BATCH') {ALPHABET_BATCHcount ++}

                    temp.push({
                        name : value.name,
                        type : t,
                        valueType : o.key,
                        value : value.value,
                        size : value.value.length,
                        description : value.description
                    })
                } else {
                    if (!hasContent(value.name)) {MessageService.error('码段信息输入错误，名称为必填项 ！');return;}
                    if (!hasContent(value.typeIndex)) {MessageService.error('码段信息输入错误，属性为必填项 ！');return;}
                    if (!hasContent(value.valueTypeIndex)) {MessageService.error('码段信息输入错误，组成为必填项 ！');return;}
                    if (!hasContent(value.value)) {MessageService.error('码段信息输入错误，样例默认值为必填项 ！'); return;}
                }
            }

            if (SERIALCount !== 1) {
                console.log('SERIALCount error:',SERIALCount);
                MessageService.error('码段信息输入错误，自增流水号码段必须有且只有一个 ！');
                return;
            }
            if (vm.codeRuleEntity.level == 1 && vm.codeRuleEntity.stackLimits) {
                if (vm.codeRuleEntity.stackLimits > 2000) {
                    MessageService.error("垛码堆垛上限数量不能超过 2000");
                    return;
                }
            }

            vm.codeRuleEntity.segments = temp;
            vm.codeRuleEntity.qrDomainUrl = vm.codeRuleEntity.urlPrefix+(vm.codeRuleEntity.urlAddress||'');

            console.log(DIGITS_PRODUCTcount,ALPHABET_CATEGORYcount,ALPHABET_BATCHcount);

            if (DIGITS_PRODUCTcount===0 || ALPHABET_CATEGORYcount===0 || ALPHABET_BATCHcount===0) {
                var content = '';
                if (DIGITS_PRODUCTcount === 0) {content += " 货品序号"}
                if (ALPHABET_CATEGORYcount === 0) {content += " 机器类别"}
                if (ALPHABET_BATCHcount === 0) {content += " 批次"}
                MessageService.confirm('码段中没有'+content+'信息，是否继续 ？', function () {
                    if (vm.codeRuleEntity.id && vm.codeRuleEntity.batchCounter===0) {
                        BaseCodeRuleService.edit(vm.codeRuleEntity,function (res) {
                            MessageService.success("编辑成功!");
                            $uibModalInstance.close();
                        },function (error) {
                            MessageService.error("编辑失败!" + error.title||'');
                        });
                    } else {
                        vm.codeRuleEntity.id = null;
                        BaseCodeRuleService.create(vm.codeRuleEntity,function (res) {
                            MessageService.success("创建成功!");
                            $uibModalInstance.close();
                        },function (error) {
                            MessageService.error("创建失败!" + error.title||'');
                        });
                    }
                });
            } else {
                if (vm.codeRuleEntity.id && vm.codeRuleEntity.batchCounter===0) {
                    BaseCodeRuleService.edit(vm.codeRuleEntity,function (res) {
                        MessageService.success("编辑成功!");
                        $uibModalInstance.close();
                    },function (error) {
                        MessageService.error("编辑失败!" + error.title||'');
                    });
                } else {
                    vm.codeRuleEntity.id = null;
                    BaseCodeRuleService.create(vm.codeRuleEntity,function (res) {
                        MessageService.success("创建成功!");
                        $uibModalInstance.close();
                    },function (error) {
                        MessageService.error("创建失败!" + error.title||'');
                    });
                }
            }
        };

        function hasContent(value) {
            console.log(value);
            return value===0||(value!==''&&value!==undefined&&value!=null);
        }

        vm.timeout = null;
        vm.nameChanging = function (name) {
            vm.nameExists = false;
            if (vm.timeout) {
                clearTimeout(vm.timeout);
            }
            vm.timeout = setTimeout(function () {
                checkField(name);
            },1500);
        };
        function checkField(name) {
            if (!name) {
                return;
            }
            var param = {
                name:'name',
                value: name
            };
            if (vm.codeRuleEntity.id && vm.codeRuleEntity.batchCounter===0) {
                param.ref = vm.codeRuleEntity.id;
            }
            BaseCodeRuleService.checkField(param, function (res) {
                vm.nameExists = res.data.exists;
            });
        }
    }
})();
