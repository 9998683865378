(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('ProductService', ProductService)
        .factory('ProductCategoryService',ProductCategoryService);

    ProductService.$inject = ['$resource'];
    ProductCategoryService.$inject = ['$resource'];

    function ProductService($resource) {
        var service = $resource('api/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false}
        });
        return service;
    }
    function ProductCategoryService($resource) {
        var service = $resource('qr/api/product/categories/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false}
        });
        return service;
    }
})();
