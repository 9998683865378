(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeCheckController', CodeCheckController);
    CodeCheckController.$inject = ['$state', '$scope', '$uibModalInstance', 'MessageService', 'BaseCodeRuleService', 'CodeBatchService', 'entity', 'BaseBatchLogService', 'CodeItemDetailService', '$uibModal'];

    function CodeCheckController($state, $scope, $uibModalInstance, MessageService, BaseCodeRuleService, CodeBatchService, entity, BaseBatchLogService, CodeItemDetailService, $uibModal) {
        /**
         * Created by vimpans on 2019/5/10
         */
        var vm = $scope;

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        if (!entity && !entity.id) {
            vm.dismissModalAction();
            MessageService.error('错误的生码记录 !');
        }

        init();
        function init() {
            vm.codeCategory = [];
            BaseCodeRuleService.segmentTypes({},function (res) {
                vm.codeCategory = res.data.map(function (value) {
                    value.options = [];
                    return value;
                });
                CodeBatchService.get({id:entity.id}, function (res) {
                    vm.code = res.data;
                    var temp = (vm.code.codePattern||'').split('%SN%') || ['',''];
                    vm.code.prefixCode = temp[0] || '';
                    vm.code.endfixCode = temp[1] || '';
                    findCodeItemByCode(vm.code.startCode);
                    vm.code.levelName = vm.code.level==0?'产品码':'垛码';
                    vm.code.codeType = vm.code.barcode?(vm.code.qrcode?'一维码，二维码':'一维码'):(vm.code.qrcode?'二维码':'');
                    vm.codeSegments = vm.code.segments.sort(function (a, b) {
                        return a.seq-b.seq;
                    });
                    initCodeSegments();
                });
            });
        }

        initCodeBathLog();
        vm.logPage = 0;
        vm.logEnd = false;
        vm.logUpdating = false;
        vm.batchLogs = [];
        function initCodeBathLog() {
            vm.logUpdating = true;
            BaseBatchLogService.query({
                page: vm.logPage,
                size: 10,
                batchId: entity.id
            }, function (res) {
                vm.batchLogs = vm.batchLogs.concat(res.data);
                if (res.info.count === vm.batchLogs.length) {
                    vm.logEnd = true;
                }
                vm.logUpdating = false;
            },function () {
                vm.logUpdating = false;
            });
        }
        vm.uploadLogsAction = function () {
            vm.logPage ++;
            initCodeBathLog();
        };

        vm.codeSegments = [];
        function initCodeSegments() {
            for (var i=0; i<vm.codeSegments.length; i++) {
                var codeInfo = vm.codeSegments[i];
                var t = typePosition(codeInfo.type);
                var type = vm.codeCategory[t];
                codeInfo.typeIndex = t;
                codeInfo.typeName = type.name;
                valueTypePosition(i);
            }
        }
        function typePosition(type) {
            var index = null;
            for (var i=0; i<vm.codeCategory.length; i++) {
                if (vm.codeCategory[i].value === type) {
                    index = i;
                }
            }
            return index;
        }
        function valueTypePosition(index) {
            var info = vm.codeSegments[index];
            var cc = vm.codeCategory[info.typeIndex];
            if (!cc.options || cc.options.length===0) {
                BaseCodeRuleService.segmentOptions({type:cc.value},function (res) {
                    cc.options = res.data;
                    for (var i=0; i<cc.options.length; i++) {
                        var valueType = cc.options[i];
                        if (valueType.key === info.valueType) {
                            info.valueType = valueType.key;
                            info.valueTypeIndex = i;
                            info.valueTypeName = valueType.name;
                            info.valueSource = valueType.source;
                        }
                    }
                });
            } else {
                for (var i=0; i<cc.options.length; i++) {
                    var valueType = cc.options[i];
                    if (valueType.key === info.valueType) {
                        info.valueType = valueType.key;
                        info.valueTypeIndex = i;
                        info.valueTypeName = valueType.name;
                        info.valueSource = valueType.source;
                    }
                }
            }
        }

        vm.codeItem = null;
        vm.itemValue = null;
        vm.searchCodeItemAction = function (e) {
            if (e) {
                if (e.keyCode === 13) {
                    search();
                }
            } else {
                search();
            }
        };
        function search() {
            if (!vm.itemValue) {
                MessageService.error('码值流水号不能为空 ！');
                return;
            }
            if (vm.itemValue && vm.itemValue.length!==(vm.code.startSerial || vm.code.endSerial).length) {
                MessageService.error('该码值错误！');
                return;
            }

            var code = (vm.code.prefixCode||'')+(vm.itemValue||'')+(vm.code.endfixCode||'');
            findCodeItemByCode(code);
        }
        function findCodeItemByCode(code) {

            CodeItemDetailService.findDetails({
                code: code
            },function (res) {
                if (res.data) {
                    vm.codeItem = res.data;
                    if (!vm.codeItem.children) {
                        vm.codeItem.children = [];
                    }
                    vm.codeItem.childList = vm.codeItem.children.map(function (val) {
                        return val.codeValue;
                    });
                    if (vm.codeItem.cancelled) {
                        vm.codeItem.status = '已作废';
                    } else if (vm.codeItem.delivered) {
                        vm.codeItem.status = '物流已出库';
                    }  else if (vm.codeItem.outboard) {
                        vm.codeItem.status = '工厂已出库';
                    } else if (vm.codeItem.inboard) {
                        vm.codeItem.status = '已入库';
                    } else if (vm.codeItem.stacked) {
                        vm.codeItem.status = '已堆垛';
                    } else if (vm.codeItem.returned)  {
                        vm.codeItem.status = '已退回';
                    } else {
                        vm.codeItem.status = '未堆垛';
                    }
                } else {
                    MessageService.error('该码值错误！');
                }
            },function (error) {
                console.log('find codeItem by code fail:',error);
                MessageService.error('该码值错误！');
            });
        }
        vm.cancelCodeItemAction = function () {
            if (!vm.codeItem) {
                MessageService.error('未查询到单码 !');
            }

            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/code/code-cancel-modal.html',
                controller: 'CodeCancelModalController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {id: vm.codeItem.id, level: vm.codeItem.level, childCount: vm.codeItem.childList.length};
                    }
                }
            }).result.then(function () {
                search();
            });
        }
    }
})();
