(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ProductMgrController', ProductMgrController);
    ProductMgrController.$inject = ['$state', '$scope', '$uibModal', 'BaseProductService','MessageService'];

    function ProductMgrController($state, $scope, $uibModal, BaseProductService,MessageService) {
        /**
         * Created by vimpans on 2019/4/30
         */

        $scope.productList = [];
        $scope.searchKey = null;
        $scope.keyupAction = function (e) {

        };
        $scope.searchAction = function () {

        };

        $scope.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };

        initDataAction();
        function initDataAction() {
            BaseProductService.query({
                page: $scope.pageparams.page-1,
                size: $scope.pageparams.size,
                sort:"id,desc",
                keyword: $scope.pageparams.keyword
            },function (res) {
                $scope.productList = res.data;
                $scope.pageparams.count = res.info.count;
            })
        }
        $scope.pageChangeAction = function () {
            initDataAction();
        };
        $scope.keyupAction = function (e) {
            if (e.keyCode === 13) {
                $scope.searchAction();
            }
        };
        $scope.searchAction = function () {
            $scope.pageparams.page = 1;
            initDataAction();
        };

        $scope.statusSwitch = function (id, status, e) {
            if (status) {
                //disable action
                MessageService.confirm("确定停用该产品参数？",function(){
                    BaseProductService.disable({id:id},function () {
                        initDataAction();
                    },function (error) {
                        console.log('disable action error:',error);
                        MessageService.error('操作失败！请重试');
                        e.target.checked = true;
                    });
                },function () {
                    e.target.checked = true;
                });
            } else {
                //enable action
                BaseProductService.enable({id:id},function () {
                    initDataAction();
                },function (error) {
                    console.log('enable action error:',error);
                    MessageService.error('操作失败！请重试');
                    e.target.checked = false;
                });
            }
        };

        $scope.createProductModal = function () {
            $uibModal.open({
                animation:true,
                backdrop: 'static',
                keyboard: false,
                templateUrl: 'app/base-manager/base-product/product-create.html',
                controller: 'ProductCreateController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity:function () {
                        return null;
                    }
                }
            }).result.then(function () {
                initDataAction();
            });
        };
        $scope.editProductAction = function (p,c) {
            $uibModal.open({
                animation:true,
                backdrop: 'static',
                keyboard: false,
                templateUrl: 'app/base-manager/base-product/product-create.html',
                controller: 'ProductCreateController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity:function () {
                        p.check = c;
                        return angular.copy(p);
                    }
                }
            }).result.then(function () {
                initDataAction();
            });
        };
    }
})();
