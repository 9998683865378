(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('BaseLogisticsController', BaseLogisticsController);
    BaseLogisticsController.$inject = ['$state', '$scope', '$uibModal', 'BaseLogisticsService', 'MessageService'];

    function BaseLogisticsController($state, $scope, $uibModal, BaseLogisticsService, MessageService) {
        /**
         * Created by vimpans on 2019/6/10
         */
        var vm = $scope;

        vm.logisticsList = [];

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };
        initDataAction();
        function initDataAction() {
            BaseLogisticsService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                sort:"id,desc",
                keyword: vm.pageparams.keyword
            },function (data) {
                vm.logisticsList = data.data.map(function (value) {
                    value.address = value.addresses.filter(function (v) {
                        return v.primary;
                    })[0];
                    value.contact = value.contacts.filter(function (v) {
                        return v.primary;
                    })[0];
                    return value;
                });
                vm.pageparams.count = data.info.count;
            });
        }
        vm.pageChangeAction = function () {
            initDataAction();
        };
        vm.keyupAction = function (e) {
            if (e.keyCode === 13) {
                vm.searchAction();
            }
        };
        vm.searchAction = function () {
            vm.pageparams.page = 1;
            initDataAction();
        };

        vm.statusSwitch = function (id, status, e) {
            if (status) {
                //disable action
                MessageService.confirm("确定停用该物流公司？",function(){
                    BaseLogisticsService.disable({id:id},function () {
                        initDataAction();
                    },function (error) {
                        console.log('disable action error:',error);
                        MessageService.error('操作失败！请重试');
                        e.target.checked = true;
                    });
                },function () {
                    e.target.checked = true;
                });
            } else {
                //enable action
                BaseLogisticsService.enable({id:id},function () {
                    initDataAction();
                },function (error) {
                    console.log('enable action error:',error);
                    MessageService.error('操作失败！请重试');
                    e.target.checked = false;
                });
            }
        };
        vm.createLogisticsModal = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/base-manager/base-logistics/logistics-create.html',
                controller: 'LogisticsCreateController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {
                initDataAction();
            });
        };
        vm.editLogisticsAction = function (id,c) {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/base-manager/base-logistics/logistics-create.html',
                controller: 'LogisticsCreateController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {id:id,check:c};
                    }
                }
            }).result.then(function () {
                initDataAction();
            });
        }
    }
})();
