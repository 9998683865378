(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('UserController', UserController);

    UserController.$inject = ['$scope', 'Principal', 'LoginService', '$state','User','MessageService','ParseLinks','$stateParams','Role','$rootScope','$uibModal','JhiLanguageService'];

    function UserController ($scope, Principal, LoginService, $state,User,MessageService,ParseLinks,$stateParams,Role,$rootScope,$uibModal,JhiLanguageService) {
        var vm = this;

        // 判断是否觉有访问该页面的权限
        Principal.hasAuthority('ROLE_USER_VIEW').then(function (data) {
            if (!data) {
                MessageService.info('不好意思，你不具有用户列表访问权限，请联系管理员。');
                $state.go("home");
            }
        });

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.back = back;
        vm.backHome = backHome;
        vm.transition = transition;
        vm.userTransition = userTransition;
        vm.clear = clear;
        vm.userClear = userClear;
        vm.edit = edit;
        vm.reset = reset;
        // vm.active = active;
        vm.selectedLine = null;
        // 分页默认参数
        vm.pagingParams = {
            ascending: true,
            page:1,
            predicate: "id",
            sort: "id,desc",
            pageSize: 10
        };
        vm.page = vm.pagingParams.page;
        vm.reverse = vm.pagingParams.ascending;
        vm.pageSize = vm.pagingParams.pageSize;
        vm.predicate = vm.pagingParams.predicate;
        vm.page = 1;
        vm.pageSize = 10;
        vm.stepPage = 1;
        vm.minPage = 1;
        vm.count = 0;

        $scope.pageChangeAction = function () {
            loadUserAll();
        };

        vm.search = search;
        vm.userSearch = userSearch;
        // vm.page = vm.pagingParams.page;
        vm.searchQuery = null;
        vm.currentSearch = null;
        vm.userSearchQuery = null;
        vm.userCurrentSearch = false;
        vm.LangKeyMap = {
            "zh-cn":"中文",
            "en":"英文"
        };

        vm.enabled = [
            {code:"all",name:"显示所有"},
            {code:"onlyEnabled",name:"只显示启用"},
            {code:"onlyDisabled",name:"只显示禁用"}
        ];

        vm.advSearch = advSearch;

        vm.adv = "all";

        function advSearch(){
            vm.userSearchQuery = null;
            vm.selectedLine = null;
            User.queryFilt({
                filter:vm.adv,
                page: vm.page-1,
                size: vm.pageSize,
                sort: sort()
            },onUserSuccess,onUserError);
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });
        $scope.$on('createRole', function(event, result) {
            loadRoleAll();
            vm.module="user-role";
        });

        getAccount();
        loadUserAll();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account.data;
                console.log('vm.account',vm.account);
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        function register () {
            $state.go('register');
        }
        $scope.select  = function(module){
            vm.module=module;
            if(module=="user"){
                vm.userSelected = true;
                vm.roleSelected = false;
                loadUserAll();
            }else{
                vm.roleSelected = true;
                vm.userSelected = false;
                loadRoleAll();
            }
        };
        function loadUserAll () {
            vm.module = "user";
            vm.selectedLine = null;
            User.query({
                page: vm.page-1,
                size: vm.pageSize,
                sort: sort()
            }, onUserSuccess, onUserError);
        }

        function loadRoleAll () {
            if (vm.pagingParams.search) {
                RoleSearch.query({
                    keyword: vm.pagingParams.search,
                    page: vm.page - 1,
                    size: vm.pageSize,
                    sort: sort()
                }, onRoleSuccess, onRoleError);
            } else {
                Role.query({
                    page: vm.page - 1,
                    size: vm.pageSize,
                    sort: sort()
                }, onRoleSuccess, onRoleError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onRoleSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.roles = data;
                vm.page = vm.page;
                if(vm.page*vm.pageSize<vm.queryCount){
                    vm.ra = vm.page*vm.pageSize;
                }else{
                    vm.ra = vm.queryCount;
                }
                vm.rb = vm.pageSize;
                vm.rc = Math.ceil(vm.queryCount/vm.pageSize);
                }
            function onRoleError(error) {
                MessageService.error(error.data.message);
            }
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onUserSuccess(data, headers) {
            var hiddenUsersSize = 0;
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                    hiddenUsersSize++;
                }
            }

            vm.totalItems = data.info.count;
            vm.count = vm.totalItems;
            vm.page = vm.page;
            vm.users = data.data;

            if(JhiLanguageService.getCurrent().$$state.value=='en'){
                vm.enFlag = true;
            }else{
                vm.enFlag = false;
            }
        }

        function onUserError(error) {
            MessageService.error(error.data.message);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            if(vm.currentSearch!=null&&vm.currentSearch!=""){
                Rolequery({
                    keyword: vm.currentSearch,
                    page: vm.page - 1,
                    size: vm.pageSize,
                    sort: sort()
                },function(data){
                    vm.totalItems = data.length;
                    vm.roles = data;
                    vm.queryCount = data.length;
                });
            }else{
                Role.query({
                    page: vm.page - 1,
                    size: vm.pageSize,
                    sort: sort()
                },function(data){
                    vm.roles = data;
                    vm.queryCount = data.length;
                });
            }
        }
        function userTransition () {
            if(vm.userSearchQuery!=null&&vm.userSearchQuery!=""){
                User.query({
                    keyword: searchQuery,
                    enabled:vm.adv,
                    page: vm.page-1,
                    size: vm.pageSize,
                    sort: sort()
                },onUserSuccess,onUserError);
            }else{
                User.query({
                    page: vm.page-1,
                    enabled:vm.adv,
                    size: vm.pageSize,
                    sort: sort()
                },onUserSuccess,onUserError);
            }
        }
        function back () {
            vm.module = null;
        }
        function backHome () {
            $state.go("home");
        }
        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.searchQuery = null;
            search(null);
        }
        function userClear(){
            vm.userCurrentSearch = false;
            vm.userSearchQuery = null;
            userSearch(null);
        }
        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.pagingParams.predicate = '_score';
            vm.pagingParams.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }
        function userSearch(searchQuery){
            vm.selectedLine = null;
            if(searchQuery!=null&&searchQuery!=""){
                User.query({
                    keyword: searchQuery,
                    enabled:vm.adv,
                    page: 0,
                    size: vm.pageSize,
                    sort: sort()
                },onUserSuccess,onUserError);
            }else{
                User.query({
                    page: 0,
                    enabled:vm.adv,
                    size: vm.pageSize,
                    sort: sort()
                },onUserSuccess,onUserError);
            }
        }
        function edit(){
            $uibModal.open({
                keyboard: false,
                templateUrl: 'app/user/user-edit.html',
                controller: 'UserEditController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return vm.selectedLine;
                    }
                }
            })
        }
        function reset(){
            $uibModal.open({
                keyboard: false,
                templateUrl: 'app/user/reset-password.html',
                controller: 'ResetPasswordController',
                controllerAs: 'vm',
                backdrop: 'static',
                // size: 'lg',
                resolve: {
                    entity: function () {
                        return vm.selectedLine;
                    }
                }
            }).result.then(function(backData){
                loadUserAll();
            });
        }

        $scope.userActive = function (user,e){
            if(user==null){
                user = vm.selectedLine;
            }
            if(user.activated){
                MessageService.confirm("确定停用用户？",function(){
                    if(user!=null){
                        vm.selectedLine = user;
                    }
                    vm.selectedLine.activated = !vm.selectedLine.activated;
                    var postData = {};
                    postData.id = vm.selectedLine.id;
                    postData.activated = vm.selectedLine.activated;
                    User.active(postData,function(data){
                        loadUserAll();
                    });
                },function () {
                    e.target.checked = true;
                });
            }else{
                if(user!=null){
                    vm.selectedLine = user;
                }
                vm.selectedLine.activated = !vm.selectedLine.activated;
                var postData = {};
                postData.id = vm.selectedLine.id;
                postData.activated = vm.selectedLine.activated;
                User.active(postData,function(data){
                    loadUserAll();
                });
            }
        };

        vm.userKeySearch = function (searchQuery, e) {
            var keycode = window.event?e.keyCode:e.which;
            if(keycode === 13){
                userSearch(searchQuery);
            }
        };

        $scope.createUserModal = function () {
            $uibModal.open({
                animation:true,
                templateUrl: 'app/user/user-create.html',
                controller: 'UserCreateController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {
                loadUserAll();
            });
        };
        $scope.editUserModal = function (u) {
            if (!u) {return;}
            $uibModal.open({
                animation:true,
                templateUrl: 'app/user/user-create.html',
                controller: 'UserCreateController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity:function () {
                        return u;
                    }
                }
            }).result.then(function () {
                loadUserAll();
            });
        };
    }
})();
