(function() {
    'use strict';
    angular
        .module('gwApp')
        .directive('page', page);

    page.$inject = ['MessageService', '$timeout'];

    function page(MessageService, $timeout){
        var directive = {
            restrict:"EA",//指令调用模式
            priority: 0,//指令优先级
            terminal: false,
            replace:true,
            template: function(tElement, tAttrs){
                var template =  "<div>"+
                                "<div style='width:{{260+pageList.length*40}}px;height:100%;display: inline-block;'>"+
                                "<div style='float:left; padding-right:15px;'>"+
                                "<i class='fa fa-angle-left' style='font-size: 20px;' ng-class='{pageI:pageCp==0}' ng-click=resetMin('left')></i>"+
                                "</div>"+
                                "<div style='float:left;margin-top: -4px;'>"+
                                "<button class='pageB' ng-class='{pageColor:pageCp===p}' ng-click='stepTo(p);' ng-repeat='p in pageList track by $index'>{{p}}</button>"+
                                "</div>"+
                                "<div style='float:left; padding-left:15px;'>"+
                                "<i class='fa fa-angle-right' style='font-size: 20px;' ng-class='{pageI:page>=totalPages}' ng-click=resetMin('right')></i></div>"+
                                "<select class='pageS' name='size' ng-model='pagePs' ng-options='o.code as o.name for o in sizeList' ng-change='stepTo()'></select>"+
                                "<div style='float:left;margin-left: 10px;'>"+
                                "跳至<input style='width: 40px !important;border-color: #ddd !important;border-radius: 4px;margin-top: -4px;margin-left: 5px;margin-right: 5px;text-align: center;' type='number' ng-model='stepPage' ng-blur='stepTo(stepPage)' ng-keyup='inputKeyUp($event)'/>页"+
                                "</div>"+
                                "</div>"+
                                "</div>";
                return template;
            },
            scope: {
                pageCp:'=',
                pagePs:'=',
                pageCount:'=',
                pageChange:'&'
            },
            controller: ['$scope','$element','$attrs',function($scope, $element, $attrs) {
                var vm = $scope;
                vm.sizeList = [
                    {name:"10条/页",code:10},
                    {name:"15条/页",code:15},
                    {name:"20条/页",code:20}
                ];
                vm.stepPage = null;
                vm.totalPages = 1;

                vm.$watch('pageCount',function () {
                    vm.totalPages = 1;
                    if (vm.pageCount && vm.pagePs) {
                        vm.totalPages = Math.ceil(vm.pageCount/vm.pagePs)===0?1:Math.ceil(vm.pageCount/vm.pagePs);
                        setTimeout(function () {
                            generatorPageList();
                        })
                    }
                });
                vm.$watch('pagePs',function () {
                    vm.totalPages = 1;
                    if (vm.pageCount && vm.pagePs) {
                        vm.totalPages = Math.ceil(vm.pageCount/vm.pagePs)===0?1:Math.ceil(vm.pageCount/vm.pagePs);
                        setTimeout(function () {
                            generatorPageList();
                        })
                    }
                });

                function generatorPageList() {
                    if (!vm.totalPages) {
                        return;
                    }
                    if(vm.totalPages<=10){
                        vm.pageList = [];
                        for(var i=0; i<vm.totalPages; i++) {
                            vm.pageList.push(i+1);
                        }
                    }else{
                        if(vm.pageCp<=6){
                            vm.pageList = [1,2,3,4,5,6,7,"...",vm.totalPages-1,vm.totalPages];
                        }else{
                            if(vm.totalPages-vm.pageCp<6){
                                vm.pageList = [1,2,"...",vm.totalPages-6,vm.totalPages-5,vm.totalPages-4,vm.totalPages-3,vm.totalPages-2,vm.totalPages-1,vm.totalPages];
                            }else{
                                vm.pageList = [1,2,"...",vm.pageCp-1,vm.pageCp,vm.pageCp+1,vm.pageCp+2,"...",vm.totalPages-1,vm.totalPages];
                            }
                        }
                    }
                }
                generatorPageList();

                vm.stepTo =  function(count){
                    if(count==="..."){
                        return;
                    }
                    if(count!=null&&count!==''){
                        if(count>vm.totalPages){
                            count = vm.totalPages;
                        }
                        vm.pageCp = count;
                        vm.stepPage = null;
                        generatorPageList();
                        $timeout(function () {
                            vm.pageChange();
                        });
                    }else{
                        generatorPageList();
                        $timeout(function () {
                            vm.pageChange();
                        });
                    }
                };
                vm.resetMin = function(direction){
                    if(direction==='left'){
                        if(vm.pageCp>1){
                            vm.pageCp--;
                            generatorPageList();
                            $timeout(function () {
                                vm.pageChange();
                            });
                        }
                    }else{
                        if(vm.pageCp<vm.totalPages){
                            vm.pageCp++;
                            generatorPageList();
                            $timeout(function () {
                                vm.pageChange();
                            });
                        }
                    }
                };
                vm.inputKeyUp = function (e) {
                    if (e.keyCode === 13) {
                        vm.stepTo(vm.stepPage);
                    }
                }
            }],
            link: function(scope, element, attrs, SomeController) {

            }
        };

        return directive;
    }
})();
