(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('SmartDocMgrController', SmartDocMgrController);
    SmartDocMgrController.$inject = ['$state', '$scope', 'SmartDocService', 'MessageService'];

    function SmartDocMgrController($state, $scope, SmartDocService, MessageService) {
        /**
         * Created by vimpans on 2019/5/30
         */
        var vm = $scope;
        vm.smartDocList = [];

        // vm.pageparams = {
        //     page: 1,
        //     size: 10,
        //     count: 0
        // };
        initDataAction();
        function initDataAction() {
            SmartDocService.getAll({docType:"CodeBatch"}, function (res) {
                vm.smartDocList = res.data.map(function (val) {
                    var fields = val.fields||[];
                    var seniorSearchShowing = {
                        category: "全部",
                        level: "全部",
                        product: "全部",
                        customer: "全部",
                        customerId: null,
                        supplier: "全部",
                        supplierId: null,
                        rule: "全部",
                        ruleId: null,
                        generateDateStartStatus:false,
                        generateDateStart:null,
                        generateDateStartEnd:false,
                        generateDateEnd:null,
                        generate: false,
                        download: false,
                        inboard: false,
                        outboard: false,
                        active: false,
                        stack :false,
                        cancel: false
                    };
                    for (var i=0; i<fields.length; i++) {
                        var field = fields[i];
                        if (field.field === "category" && field.value) {
                            seniorSearchShowing.category = field.value;
                        }
                        if (field.field === "rule.level" && field.value) {
                            seniorSearchShowing.level = field.valueTitle;
                        }
                        if (field.field === "rule.id" && field.value) {
                            seniorSearchShowing.ruleId = field.value;
                            seniorSearchShowing.rule = field.valueTitle;
                        }
                        if (field.field === "deliveryItems.customerId" && field.value) {
                            seniorSearchShowing.customerId = field.value;
                            seniorSearchShowing.customer = field.valueTitle;
                        }
                        if (field.field === "supplier.id" && field.value) {
                            seniorSearchShowing.supplierId = field.value;
                            seniorSearchShowing.supplier = field.valueTitle;
                        }
                        if (field.field === "product.number" && field.value) {
                            seniorSearchShowing.product = field.value;
                        }
                        if (field.field === "createdDate") {
                            if (field.value && field.value!=0) {
                                seniorSearchShowing.generateDateStart = new Date(field.value);
                            }
                            if (field.value2 && field.value!=0) {
                                seniorSearchShowing.generateDateEnd = new Date(field.value2);
                            }
                        }
                        if (field.field === "generated") {
                            seniorSearchShowing.generate = true;
                        }
                        if (field.field === "download") {
                            seniorSearchShowing.download = true;
                        }
                        if (field.field === "inboard") {
                            seniorSearchShowing.inboard = true;
                        }
                        if (field.field === "outboard") {
                            seniorSearchShowing.outboard = true;
                        }
                        if (field.field === "activated") {
                            seniorSearchShowing.active = true;
                        }
                        if (field.field === "stacking") {
                            seniorSearchShowing.stack = true;
                        }
                        if (field.field === "cancelled") {
                            seniorSearchShowing.cancel = true;
                        }
                    }
                    var seniorString = "";
                    seniorString = "码等级："+seniorSearchShowing.level+"；生码时间：";
                    var s = "全部";
                    if (seniorSearchShowing.generateDateStart && seniorSearchShowing.generateDateEnd) {
                        s = seniorSearchShowing.generateDateStart.toLocaleDateString()+"-"+seniorSearchShowing.generateDateEnd.toLocaleDateString();
                    } else if  (!seniorSearchShowing.generateDateStart && seniorSearchShowing.generateDateEnd) {
                        s = "早于"+seniorSearchShowing.generateDateEnd.toLocaleDateString();
                    } else if  (seniorSearchShowing.generateDateStart && !seniorSearchShowing.generateDateEnd) {
                        s = "晚于"+seniorSearchShowing.generateDateStart.toLocaleDateString();
                    }
                    seniorString += s;
                    seniorString += ("；机器类别："+seniorSearchShowing.category);
                    seniorString += ("；供应商："+seniorSearchShowing.supplier);
                    seniorString += ("；经销商："+seniorSearchShowing.customer);
                    seniorString += ("；码规则："+seniorSearchShowing.rule);
                    var statusList = [];
                    if (seniorSearchShowing.generate) {statusList.push("已生成");}
                    if (seniorSearchShowing.download) {statusList.push("已下载");}
                    if (seniorSearchShowing.inboard) {statusList.push("已入库>1%");}
                    if (seniorSearchShowing.outboard) {statusList.push("已出库>1%");}
                    if (seniorSearchShowing.active) {statusList.push("已激活>1%");}
                    if (seniorSearchShowing.stack) {statusList.push("已堆垛>1%");}
                    if (seniorSearchShowing.cancel) {statusList.push("已作废>1%");}
                    if (statusList.length === 0) {
                        seniorString += "；码状态：全部";
                    } else {
                        seniorString += ("；码状态："+statusList.join("、"));
                    }
                    val.seniorString = seniorString;
                    return val;
                });
            });
        }

        vm.delSmartDoc = function (id) {
            MessageService.confirm("确认删除该智能菜单吗？", function () {
                SmartDocService.delete({id:id},function (res) {
                    MessageService.success("智能菜单删除成功 ！即将刷新页面...");
                    setTimeout(function () {
                        window.location.reload();
                    },2000);
                })
            });
        }
    }
})();
