(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('PurchaseItemService', PurchaseItemService);

    PurchaseItemService.$inject = ['$resource'];

    function PurchaseItemService($resource) {
        var service = $resource('qr/api/purchaseItems/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false},
            'search': {method: 'POST', params: {param: 'search'}, isArray: false},
            'download': {
                method: 'POST',
                params: {param: 'download'},
                isArray: false,
                responseType: 'blob',
                transformResponse: function (data, hg) {
                    var filename = hg()['content-disposition'];
                    if (filename) {
                        var temp = filename.split('=');
                        filename = temp[temp.length - 1];
                    }
                    return {
                        blob: data,
                        filename: filename
                    };
                }
            }
        });
        return service;
    }
})();
