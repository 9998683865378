(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('BaseProductService', BaseProductService)
        .factory('BaseProductCategoryService', BaseProductCategoryService);

    BaseProductService.$inject = ['$resource'];
    BaseProductCategoryService.$inject = ['$resource'];

    function BaseProductService($resource) {
        var service = $resource('qr/api/products/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'edit': {method: 'POST', params: {param: 'edit'}, isArray: false},
            'enable': {method: 'POST', params: {param: 'enable'}, isArray: false},
            'disable': {method: 'POST', params: {param: 'disable'}, isArray: false},
            'checkField': {method: 'GET', params: {param: 'checkField'}, isArray: false},
            'queryAvailable': {method: 'GET', params: {param: 'queryAvailable'}, isArray: false}
        });
        return service;
    }
    function BaseProductCategoryService($resource) {
        var service = $resource('qr/api/product/categories/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false}
        });
        return service;
    }
})();
