(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('UserRoleController', UserRoleController);

    UserRoleController.$inject = ['Principal', '$rootScope', '$state', '$timeout', 'Role', 'Permission', 'MessageService'];

    function UserRoleController (Principal, $rootScope, $state, $timeout, Role, Permission, MessageService) {
        var vm = this;

        vm.role = {};
        vm.roleList = [];         // 角色列表
        vm.activatedRoleIndex = null;

        vm.authList = [];         // 权限列表
        vm.activatedAuthList = [];
        vm.authGroup = [];        // 显示分组的中间数组
        vm.plantAuthGroup = [];   // 权限列表分组[租户]
        vm.roleSelected = null;   // 用户当前选择的角色

        vm.description = null;    // 角色的描述
        vm.editRoleId = null;  // 需要编辑的角色，用于控制输入框的显示隐藏
        vm.newInput = null;    // 输入的内容
        vm.nameAdd = null;     //添加角色内容

        // vm.roleTypeList = [
        //     { name: "工厂", code: "PLANT" }
        // ];
        // vm.roleTypeSelected = vm.roleTypeList[0];

        vm.authChanged = false;

        vm.roleActivated = roleActivated;
        vm.authActivated = authActivated;
        vm.roleDelBtn = roleDelBtn;
        vm.roleAddBtn = roleAddBtn;
        vm.updateBtn = updateBtn;
        vm.newRole = newRole;
        vm.checkAuth = checkAuth;
        vm.closeModal = closeModal;

        vm.groupSelected = groupSelected;
        vm.groupSelectedAll = groupSelectedAll;
        vm.roleDesChange = roleDesChange;
        vm.counter = counter;
        vm.canEditRole = false;
        vm.canEditAuthority = canEditAuthority;
        vm.canEditGroup = canEditGroup;

        loadRole();
        loadPermission();
        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;

                if (Principal.hasAuthority("ROLE_ROLE_EDIT")) {
                    vm.canEditRole = true;
                }
            });
        }

        function canEditGroup(group) {
            if (vm.roleSelected && vm.roleSelected.id == 1) {
                var adminGroups = ["用户管理", "基础数据管理"];
                if (group && adminGroups.indexOf(group) != -1) {
                    return false;
                }
            }
            return true;
        }

        function canEditAuthority(authority) {

            if (vm.roleSelected && vm.roleSelected.id == 1) {
                var adminAuthorities = [
                    "ROLE_USER_ADD", "ROLE_USER_EDIT", "ROLE_USER_FORBID", "ROLE_USER_VIEW", 
                    "ROLE_ROLE_ADD", "ROLE_ROLE_EDIT", "ROLE_ROLE_FORBID", "ROLE_ROLE_VIEW"];
                if (authority && adminAuthorities.indexOf(authority) != -1) {
                    return false;
                }

            }

            return true;
        }

        // 角色选择
        function roleActivated(id) {
            vm.mlRoleList = id;
            // 如果是编辑状态则恢复原来数据
            if(vm.editRoleId !== id) {
                vm.editRoleId = null;
            }

            if(vm.authChanged) {
                MessageService.confirm("角色 [" + getRoleById(vm.activatedRoleIndex).name + "]权限已经修改，需要更新保存？", function () {
                    vm.updateBtn();

                    // 更新为异步请求，故作延迟处理激活新选择的角色
                    $timeout(function () {
                        activate(id);
                        vm.authChanged = false;
                    }, 300);

                    // 清空 Role 内容
                    vm.role = {};
                    // vm.roleTypeSelected = vm.roleTypeList[0];
                }, function () {
                    activate(id);
                    vm.authChanged = false;

                    // 清空 Role 内容
                    vm.role = {};
                    // vm.roleTypeSelected = vm.roleTypeList[0];
                });
            } else {
                activate(id);
            }

            function activate(id) {
                vm.activatedRoleIndex = id;

                // 获取当前角色
                vm.roleSelected = getRoleById(id);
                if(vm.roleSelected === null) {
                    return;
                }

                // 当前角色的描述内容
                vm.description = vm.roleSelected.description;

                // 当前选择角色的权限
                vm.activatedAuthList = vm.roleSelected.authorities.concat();

                vm.authDroup = vm.plantAuthGroup;
            }
        }
        function getRoleById(id) {
            for(var i = 0; i < vm.roleList.length; i++) {
                if(vm.roleList[i].id === id) {
                    return vm.roleList[i];
                }
            }

            return null;
        }

        // 角色描述更改
        function roleDesChange() {
            vm.authChanged = true;
        }

        // 权限点选事件
        function authActivated(id) {
            vm.authChanged = true;

            var index = isActivated(id);
            if(index === -1) { // add
                vm.activatedAuthList.push(getAuthById(id));
            } else {           // delete
                vm.activatedAuthList.splice(index, 1);
            }
        }
        function isActivated(id) {
            for(var i = 0; i < vm.activatedAuthList.length; i++) {
                if(id === vm.activatedAuthList[i].id) {
                    return i;
                }
            }

            return -1;
        }
        function getAuthById(id) {
            for (var i = 0; i < vm.authList.length; i++) {
                if(vm.authList[i].id === id) {
                    return vm.authList[i];
                }
            }

            return null;
        }

        // check box
        function checkAuth(id) {
            return isActivated(id) !== -1;
        }

        function roleDelBtn(role) {
            if(1 === role.id || 2 === role.id || role.builtin) {
                MessageService.error("该角色不可以删除!");
                return;
            }

            var roleName = role.name;
            MessageService.confirm("您确认删除角色 " + role.name + "？", function () {
                Role.delete({
                    id: role.id
                }, function (data) {
                    // 修改角色选项
                    // vm.roleList.splice(getRoleById(data.id), 1);
                    // vm.activatedRoleIndex = vm.roleList[0].id;

                    // 刷新显示
                    $state.reload();

                    MessageService.success("删除角色[" + roleName + "]成功");
                }, function (error) {
                    MessageService.error("删除角色[" + roleName + "]失败");
                });
            });
        }

        function roleAddBtn() {
            // 清空 Role 内容
            vm.role = {};
            // vm.roleTypeSelected = vm.roleTypeList[0];

            // 打开创建Role模态框
            $('#newRoleModal').modal('toggle');
        }

        function updateBtn() {
            if(!vm.authChanged) {
                return;
            }
            vm.authChanged = false;

            vm.role = getRoleById(vm.activatedRoleIndex);
            vm.role.authorities = vm.activatedAuthList;
            // for(var i = 0; i < vm.activatedAuthList.length; i++){
            //     vm.role.authorities.push(vm.activatedAuthList[i].id);
            // }

            // 修改描述内容
            if(!isEmpty(vm.description)) {
                vm.role.description = vm.description;
            }

            Role.update(vm.role, function (data) {
                // 刷新显示
                // $state.reload();

                // 修改 RoleList 中的对象
                vm.activatedRoleIndex = data.id;
                for(var i = 0; i < vm.roleList.length; i++) {
                    if(vm.roleList[i].id === data.id) {
                        vm.roleList[i] = data;
                        break;
                    }
                }

                MessageService.success("更新角色[" + data.name + "]成功, 刷新浏览器获取即可新权限！");

                // 恢复编辑角色名称显示样式
                if(!isEmpty(vm.editRoleId)) {
                    editRoleNameInit();
                }

                // 刷新显示条件
                if(vm.account.roleId === data.id) {
                    $rootScope.$broadcast('changeRoleName', data.name);
                }

                // 角色更新
                roleActivated(data.id);
            }, function (error) {
                MessageService.error(error.data.message);
            });
        }

        function closeModal() {
            vm.role = {};
        }
        vm.roleAdd = {};
        function newRole() {
            // vm.roleAdd.type = vm.roleTypeSelected.code;
            vm.roleAdd.authorities = [];
            vm.roleAdd.name = vm.nameAdd;
            Role.save(vm.roleAdd, function (result) {

                if (result.type === 'PLATFORM') {
                    vm.roleList.unshift(result);
                } else {
                    vm.roleList.push(result);
                }

                // 激活当前新增的角色
                roleActivated(result.id);

                // 清空 Role 内容
                vm.roleAdd = {};
                // vm.roleTypeSelected = vm.roleTypeList[0];
                vm.nameAdd = null;

                MessageService.success("新增角色成功");
            }, function (error) {
                MessageService.error(error.data.message);
            });
        }

        function isEmpty(target) {
            return target === '' || target === null || target === undefined;
        }

        function loadRole() {
            Role.getAll({}, function (data) {

                // for (var i in data) {
                //     var r = data[i];
                //     if (r.type==='PLATFORM') {
                //         data.splice(i,1);
                //         data.unshift(r);
                //     }
                // }

                vm.roleList = data;

                // 激活权限
                vm.activatedRoleIndex = isEmpty(vm.activatedRoleIndex) ? vm.roleList[0].id : vm.activatedRoleIndex;
                roleActivated(vm.activatedRoleIndex);
            }, function (error) {
                MessageService.error(error.data.message);
            })
        }

        function loadPermission(){
            Permission.query(function(data) {
                vm.authList = data.filter(function (val) {
                    return val.name!=="管理员"&&val.name!=="销售单号查看";
                });
            //
            //     // 提取分组
            //     for(var i = 0; i < vm.authList.length; i++) {
            //
            //         if(!hasGroup(vm.authList[i].group, false)) {
            //             vm.plantAuthGroup.push(vm.authList[i].group);
            //         }
            //     }
            //
            //     console.log('plantAuthGroup',vm.plantAuthGroup);
            });
            vm.plantAuthGroup = ["单号查看","生码管理","用户管理","基础数据管理","PDA功能","系统设置"];
        }

        // 判断是否已经存在权限分组
        function hasGroup(group) {
            var list = vm.plantAuthGroup;

            if(list === null || list === undefined) {
                vm.plantAuthGroup = new Array;
                return false;
            }

            for(var i in list) {
                if(group === list[i]) {
                    return true;
                }
            }

            return false;
        }

        // 权限组选择
        function groupSelected(group) {
            vm.authChanged = true;

            // 判断是否全选
            var selectedAll = groupSelectedAll(group);  // 原来的 check 状态

            // 遍历
            for(var i = 0; i < vm.authList.length; i++) {
                if(group === vm.authList[i].group) {
                    var index = isActivated(vm.authList[i].id);
                    if(selectedAll) { // 已经全选
                        if(index !== -1) { // delete
                            vm.activatedAuthList.splice(index, 1);
                        }
                    } else { // 没有全选的状态
                        if(index === -1) { // add
                            vm.activatedAuthList.push(getAuthById(vm.authList[i].id));
                        }
                    }
                }
            }
        }

        // 判断权限组是否全选
        function groupSelectedAll(group) {
            // 遍历确认是否全选
            for(var i = 0; i < vm.authList.length; i++) {
                if(group === vm.authList[i].group) {
                    var index = isActivated(vm.authList[i].id);
                    if(index === -1) {
                        return false;
                    }
                }
            }

            return true;
        }

        // 角色列表显示使用
        // group -- 当前的权限分组; index -- 当前权限分组列表的index
        function counter(index) {
            if((index === 0) || (index % 3 === 1) || (index % 3 === 0)) {
                return;
            }

            var lastCnt = cnt(vm.authDroup[index - 1]);
            var nowCnt = cnt(vm.authDroup[index]);

            var empty = 'empty';
            var res = [];
            if(nowCnt >= lastCnt) {
                return;
            }

            // 循环添加内容
            for(var i = 0; i < lastCnt - nowCnt; i++) {
                res.push(empty);
            }

            return res;
        }

        function cnt(group) {
            var cnt = 0;
            for(var i = 0; i < vm.authList.length; i++) {
                if(vm.authList[i].group === group) {
                    cnt++;
                }
            }
            return cnt;
        }

        // 角色双击事件
        vm.roleDbClick = roleDbClick;
        function roleDbClick(element) {
            if (vm.canEditRole) {
                vm.editRoleId = element.valueOf().role.id;
                vm.newInput = null;

                // 触发输入框焦点
                var inputObj = document.getElementById('input_' + vm.editRoleId);
                if(!isEmpty(inputObj)) {
                    inputObj.focus();
                }
            }
        }

        // 修改角色名提交
        vm.resetRoleName = resetRoleName;
        function resetRoleName(roleId) {
            if(isEmpty(vm.newInput)) {
                // 提示内容不能为空
                MessageService.info("请输入角色名称");
                return;
            }

            // 更改角色的名称
            vm.roleSelected.name = vm.newInput;

            // 更新角色名称
            vm.authChanged = true;
            updateBtn();
        }

        // 取消更改角色名称
        vm.cancelEdit = cancelEdit;
        function cancelEdit(roleId) {
            // 可判断是否已经修改，已修改则提示是否需要保存

            vm.editRoleId = null;
        }

        function editRoleNameInit() {
            vm.editRoleId = null;  // 需要编辑的角色，用于控制输入框的显示隐藏
            vm.newInput = null;    // 输入的内容
        }
    }
})();
