(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('User', User)
        .factory('AccountUser', AccountUser);

    User.$inject = ['$resource'];
    AccountUser.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('qr/api/users/:login', {}, {
            'query': {method: 'GET'},
            'get': {
                method: 'GET',
                params: {
                    login: 'get'
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST',
                params: {
                    login: 'create'
                }
            },
            'active': { method:'GET',
                params: {
                    login: 'active'
                }
            },
            'update': { method:'POST',
                params: {
                    login: 'edit'
                }
            },
            'delete':{ method:'DELETE'},
            'reset':{ method:'POST',
                params: {
                    login: 'reset'
                },
            },
            'validate': {method:'GET',
                params: {
                    login: 'exists'
                }
            },
            'queryFilt': {method:'GET',
                params: {
                    login: 'queryFilt'
                }
            }
        });

        return service;
    }

    function AccountUser ($resource) {
        var service = $resource('api/user/:login', {}, {
            'getReport': {
                method: 'GET',
                params: {
                    login: 'getReport'
                }
            }
        });

        return service;
    }
})();
