(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('BaseCodeRuleService', BaseCodeRuleService)
        .factory('BaseBatchNOService', BaseBatchNOService)
        .factory('BaseBatchLogService', BaseBatchLogService);

    BaseCodeRuleService.$inject = ['$resource'];
    BaseBatchNOService.$inject = ['$resource'];
    BaseBatchLogService.$inject = ['$resource'];

    function BaseCodeRuleService($resource) {
        var service = $resource('qr/api/rules/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false},
            'get': {method: 'GET', params: {param: 'get'}, isArray: false},
            'edit': {method: 'POST', params: {param: 'edit'}, isArray: false},
            'segmentTypes': {method: 'GET', params: {param: 'segment/types'}, isArray: false},
            'segmentOptions': {method: 'GET', params: {param: 'segment/options'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'enable': {method: 'POST', params: {param: 'enable'}, isArray: false},
            'disable': {method: 'POST', params: {param: 'disable'}, isArray: false},
            'checkField': {method: 'GET', params: {param: 'checkField'}, isArray: false},
            'queryAvailable': {method: 'GET', params: {param: 'queryAvailable'}, isArray: false}
        });
        return service;
    }
    function BaseBatchNOService($resource) {
        var service = $resource('qr/api/batchNo/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false}
        });
        return service;
    }
    function BaseBatchLogService($resource) {
        var service = $resource('qr/api/codeBatch/logs/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false}
        });
        return service;
    }
})();
