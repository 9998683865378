(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('SaleOrderController', SaleOrderController);
    SaleOrderController.$inject = ['$state', '$scope', 'SaleOrderService', 'BaseProductCategoryService', 'BaseProductService', 'BaseCodeRuleService', 'BaseSaleService', 'BaseSupplierService'];

    function SaleOrderController($state, $scope, SaleOrderService, BaseProductCategoryService, BaseProductService, BaseCodeRuleService, BaseSaleService, BaseSupplierService) {
        /**
         * Created by vimpans on 2019/5/14
         */
        var vm = $scope;
        vm.saleOrderList = [];
        vm.seniorSearchMode = false;
        vm.seniorSearchShowing = {
            category: "全部",
            product: "全部",
            customer: "全部",
            customerId: null,
            supplier: "全部",
            supplierId: null,
            rule: "全部",
            ruleId: null,
            generateDateStartStatus:false,
            generateDateStart:null,
            generateDateStartEnd:false,
            generateDateEnd:null
        };

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };
        initDataAction();
        function initDataAction() {
            clearSeniorParams();
            SaleOrderService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                sort:"id,desc",
                keyword: vm.pageparams.keyword
            },function (res) {
                vm.saleOrderList = res.data.map(function (val) {
                    if (val.customers && val.customers.length > 0) {
                        val.customerName = val.customers[0].name;
                    } else {
                        val.customerName = "";
                    }
                    val.purchaseOrders = val.purchaseOrders.slice(0,3);
                    val.records = val.records.slice(0,3);
                    return val;
                });
                vm.pageparams.count = res.info.count;
            });
        }
        vm.pageChangeAction = function () {
            if (vm.seniorSearchMode) {
                seniorSearchRequest();
            } else {
                initDataAction();
            }
        };
        vm.keyupAction = function (e) {
            if (e.keyCode === 13) {
                vm.searchAction();
            }
        };
        vm.searchAction = function () {
            vm.seniorSearchMode = false;
            vm.pageparams.page = 1;
            initDataAction();
        };

        //高级搜索参数
        vm.popoverStatus = false;
        vm.$watch("popoverStatus", function () {
            if (!vm.popoverStatus) {
                vm.vm.mutualIndex = 0;
            }
        });
        vm.mutualIndex = function (data,index,event){
            if (index === 3) {
                vm.seniorSearchShowing.supplierId = data.id;
            } else if (index === 4) {
                vm.seniorSearchShowing.customerId = data.id;
            } else if (index === 5) {
                vm.seniorSearchShowing.ruleId = data.id;
            }
        };
        vm.productCategoryList = [];
        vm.productCategorySearch = function (val) {
            BaseProductCategoryService.query({keyword:val},function (res) {
                vm.productCategoryList = res.data;
                vm.productCategoryList.unshift({number:"全部"});
            });
        };
        vm.productList = [];
        vm.productSearch = function (val) {
            var category = null;
            if (vm.seniorSearchShowing.category && vm.seniorSearchShowing.category!=="全部") {
                category = vm.seniorSearchShowing.category;
            }
            BaseProductService.queryAvailable({keyword:val,category:category},function (res) {
                vm.productList = res.data;
                vm.productList.unshift({number:"全部"});
            });
        };
        vm.supplierList = [];
        vm.searchSupplierAction = function (val) {
            BaseSupplierService.queryAvailable({keyword:val},function (res) {
                vm.supplierList = res.data;
                vm.supplierList.unshift({name:"全部"});
            });
        };
        vm.customerList = [];
        vm.searchCustomerAction = function (val) {
            BaseSaleService.queryAvailable({keyword: val}, function (res) {
                vm.customerList = res.data;
                vm.customerList.unshift({name:"全部"});
            });
        };
        vm.codeRoleList = [];
        vm.searchCodeRoleAction = function (val) {
            BaseCodeRuleService.queryAvailable({keyword: val}, function (res) {
                vm.codeRoleList = res.data;
                vm.codeRoleList.unshift({name:"全部"});
            });
        };
        vm.seniorString = "";
        vm.seniorSearchAction = function () {
            var fields = [];
            var param = {documentType: "SalesOrder", fields: fields};

            var generateDate = {
                field: "createdDate",
                type: "DATE",
                operator: "BETWEEN",
                value: null,
                value2: null
            };
            if (!vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = "2000-01-01T00:00:00.000Z";
                generateDate.value2 = vm.seniorSearchShowing.generateDateEnd.toEISOString();
                fields.push(generateDate);
            } else if (vm.seniorSearchShowing.generateDateStart && !vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = vm.seniorSearchShowing.generateDateStart.toSISOString();
                generateDate.value2 = "2100-01-01T23:59:59.000Z";
                fields.push(generateDate);
            } else if (vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                generateDate.value = vm.seniorSearchShowing.generateDateStart.toSISOString();
                generateDate.value2 = vm.seniorSearchShowing.generateDateEnd.toEISOString();
                fields.push(generateDate);
            }

            if (vm.seniorSearchShowing.category !== "全部") {
                fields.push({
                    field: "items.batch.category",
                    type: "TEXT",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.category
                });
            }
            if (vm.seniorSearchShowing.product !== "全部") {
                fields.push({
                    field: "items.batch.product.number",
                    type: "TEXT",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.product
                });
            }
            if (vm.seniorSearchShowing.supplier !== "全部") {
                fields.push({
                    field: "items.batch.supplier.id",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.supplierId
                });
            }
            if (vm.seniorSearchShowing.rule !== "全部") {
                fields.push({
                    field: "items.batch.rule.id",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.ruleId
                });
            }
            if (vm.seniorSearchShowing.customer !== "全部") {
                fields.push({
                    field: "items.customerId",
                    type: "LONG",
                    operator: "EQ",
                    value: vm.seniorSearchShowing.customerId
                });
            }
            updateSeniorString();
            vm.seniorSearchMode = true;
            vm.pageparams.page = 1;
            vm.popoverStatus = false;
            seniorSearchRequest(param);
        };
        vm.seniorRequestParams = null;
        function seniorSearchRequest(params) {
            clearPrimaryParams();
            if (params) {
                vm.seniorRequestParams = params;
            }
            var finalParams = angular.copy(vm.seniorRequestParams);
            finalParams.page = vm.pageparams.page-1;
            finalParams.size = vm.pageparams.size;
            finalParams.sort = "id,desc";
            SaleOrderService.search(finalParams, function (res) {
                vm.saleOrderList = res.data.map(function (val) {
                    if (val.customers && val.customers.length > 0) {
                        val.customerName = val.customers[0].name;
                    } else {
                        val.customerName = "";
                    }
                    val.purchaseOrders = val.purchaseOrders.slice(0,3);
                    val.records = val.records.slice(0,3);
                    return val;
                });
                vm.pageparams.count = res.info.count;
            });
        }
        vm.seniorSearchCancel = function () {
            vm.popoverStatus = false;
        };
        function updateSeniorString() {
            var seniorString = "";
            seniorString += ("机器类别："+vm.seniorSearchShowing.category);
            seniorString += ("；货品序号："+vm.seniorSearchShowing.product);
            seniorString += ("；供应商："+vm.seniorSearchShowing.supplier);
            seniorString += ("；经销商："+vm.seniorSearchShowing.customer);
            seniorString += ("；码规则："+vm.seniorSearchShowing.rule);
            seniorString += "；操作时间：";
            var s = "全部";
            if (vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                s = vm.seniorSearchShowing.generateDateStart.toLocaleDateString()+"-"+vm.seniorSearchShowing.generateDateEnd.toLocaleDateString();
            } else if  (!vm.seniorSearchShowing.generateDateStart && vm.seniorSearchShowing.generateDateEnd) {
                s = "早于"+vm.seniorSearchShowing.generateDateEnd.toLocaleDateString();
            } else if  (vm.seniorSearchShowing.generateDateStart && !vm.seniorSearchShowing.generateDateEnd) {
                s = "晚于"+vm.seniorSearchShowing.generateDateStart.toLocaleDateString();
            }
            seniorString += s;
            vm.seniorString = seniorString;
        }

        function clearSeniorParams() {
            vm.seniorSearchShowing = {
                category: "全部",
                product: "全部",
                customer: "全部",
                customerId: null,
                supplier: "全部",
                supplierId: null,
                rule: "全部",
                ruleId: null,
                generateDateStartStatus:false,
                generateDateStart:null,
                generateDateStartEnd:false,
                generateDateEnd:null
            };
        }
        function clearPrimaryParams() {
            vm.pageparams.keyword = "";
        }

    }
})();
