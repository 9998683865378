(function() {
    'use strict';

    angular
        .module('gwApp')
        .factory('validateInput', validateInput);

    function validateInput() {
        var mathod = {};

        //印厂缩写
        mathod.isIdentifier = function (identifier) {
            if (mathod.isEmpty(identifier)) {return false}
            return identifier.match("^[A-Za-z]+[A-Za-z0-9]*$") === null;
        };

        //用户名称
        mathod.isUserName = function (name) {
            if (mathod.isEmpty(name)) {return false}
            return name.match("^[\u4e00-\u9fa5_'.A-Za-z0-9-]{8,20}$") === null;
        };

        //网址名称
        mathod.isUrlName = function (name) {
            if (mathod.isEmpty(name)) {return false}
            return name.match("^[\u4e00-\u9fa5_'.A-Za-z0-9-]{2,20}$") === null;
        };

        //网址
        mathod.isUrl = function (url) {
            if (mathod.isEmpty(url)) {return false}
            return url.match("^https:") === null;
        };

        //密码
        mathod.isPassword = function (password) {
            if (mathod.isEmpty(password)) {return false}
            return password.match("^[_'.@A-Za-z0-9-]{8,32}$") === null;
        };

        //邮箱
        mathod.isEmail = function (email) {
            if (mathod.isEmpty(email)) {return false}
            return email.match("^([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\\_|\\.]?)*[a-zA-Z0-9]+\\.[a-zA-Z]{2,3}$") === null;
        };

        //手机号码
        mathod.isPhone = function (phone) {
            if (mathod.isEmpty(phone)) {return false}
            return phone.match("^[0-9-]{11}$") === null;
        };

        mathod.isEmpty = function (target) {
            return target === null || target === undefined || target === '';
        };

        return mathod;
    }
})();
