(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeSearchController', CodeSearchController);
    CodeSearchController.$inject = ['$state', '$scope', 'CodeItemDetailService', 'MessageService', 'Principal', '$timeout'];

    function CodeSearchController($state, $scope, CodeItemDetailService, MessageService, Principal, $timeout) {
        /**
         * Created by vimpans on 2019/6/6
         */
        var vm = $scope;

        Principal.identity().then(function() {
            if (!Principal.hasAuthority("ROLE_SUPPORT_CODE_QUERY")) {
                MessageService.error('无码查询权限，请联系管理员添加权限 ！页面将在3秒后自动退出');
                $state.go("home");
            }
        });

        vm.code = null;
        vm.codeModel = null;

        vm.keyupAction = function (e) {
            if (e.keyCode === 13) {
                vm.searchAction();
            }
        };
        vm.searchAction = function () {
            if (!vm.code) {
                MessageService.error('请先输入要查询的码值 ！');
                return;
            }
            CodeItemDetailService.findDetails({
                code: vm.code
            },function (res) {
                if (res.data) {
                    vm.codeModel = res.data;
                    vm.codeModel.yearString = parseInt(vm.codeModel.year)+1983;
                    if (vm.codeModel.week) {
                        vm.codeModel.weekString = "第" + vm.codeModel.week + "周";
                    }
                    else {
                        vm.codeModel.weekString = "";
                    }
                    // if (!vm.codeItem.children) {
                    //     vm.codeItem.children = [];
                    // }
                    // vm.codeItem.childList = vm.codeItem.children.map(function (val) {
                    //     return val.codeValue;
                    // });
                } else {
                    MessageService.error('该码值错误！');
                }
            },function (error) {
                console.log('find codeItem by code fail:',error);
                MessageService.error('该码值错误！');
            });
        };

        vm.jumpAction = function () {
            $('#codeSearch').parent().removeClass("ml-menu-son-pitchOn");
            $('#code').parent().addClass("ml-menu-son-pitchOn");
        }
    }
})();
