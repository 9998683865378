(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeSeniorSearchController', CodeSeniorSearchController);
    CodeSeniorSearchController.$inject = ['$state', '$scope', 'entity', '$uibModalInstance', 'MessageService', 'SmartDocService'];

    function CodeSeniorSearchController($state, $scope, entity, $uibModalInstance, MessageService, SmartDocService) {
        /**
         * Created by vimpans on 2019/5/15
         */

        var vm = $scope;

        vm.name = "";

        if (!entity) {
            MessageService.error("读取高级搜索参数失败，请重试 ！");
            $uibModalInstance.dismiss();
        }

        vm.seniorSearchShowing = entity||{};

        vm.dismissAction = function (e) {
            $uibModalInstance.dismiss();
        };
        function translateLevel() {
            if (vm.seniorSearchShowing.level === "产品码") {
                return 0;
            } else if (vm.seniorSearchShowing.level === "垛码") {
                return 1;
            } else {
                return null;
            }
        }
        vm.saveAction = function () {

            if (!vm.name || vm.name==null || vm.name===undefined || vm.name==="") {
                MessageService.error("菜单名称为必填项");
                return;
            }

            var fields = [];
            var param = {documentType: "CodeBatch", fields: fields, name: vm.name};
            fields.push({
                title: "码等级",
                field: "rule.level",
                type: "INT",
                operator: "EQ",
                value: vm.seniorSearchShowing.level === "全部"?null:translateLevel(),
                valueTitle: vm.seniorSearchShowing.level
            });
            fields.push({
                title: "生码时间",
                field: "createdDate",
                type: "DATE",
                operator: "BETWEEN",
                value: vm.seniorSearchShowing.generateDateStart?vm.seniorSearchShowing.generateDateStart.toSISOString():"0",
                value2: vm.seniorSearchShowing.generateDateEnd?vm.seniorSearchShowing.generateDateEnd.toEISOString():"0"
            });
            fields.push({
                title: "机器类别",
                field: "category",
                type: "TEXT",
                operator: "EQ",
                value: vm.seniorSearchShowing.category === "全部"?null:vm.seniorSearchShowing.category,
                valueTitle: vm.seniorSearchShowing.category
            });
            fields.push({
                title: "货品序号",
                field: "product.number",
                type: "TEXT",
                operator: "EQ",
                value: vm.seniorSearchShowing.product === "全部"?null:vm.seniorSearchShowing.product,
                valueTitle: vm.seniorSearchShowing.product
            });
            fields.push({
                title: "供应商",
                field: "supplier.id",
                type: "LONG",
                operator: "EQ",
                value: vm.seniorSearchShowing.supplier === "全部"?null:vm.seniorSearchShowing.supplierId,
                valueTitle: vm.seniorSearchShowing.supplier
            });
            fields.push({
                title: "经销商",
                field: "deliveryItems.customerId",
                type: "LONG",
                operator: "EQ",
                value: vm.seniorSearchShowing.customer === "全部"?null:vm.seniorSearchShowing.customerId,
                valueTitle: vm.seniorSearchShowing.customer
            });
            fields.push({
                title: "码规则",
                field: "rule.id",
                type: "LONG",
                operator: "EQ",
                value: vm.seniorSearchShowing.rule === "全部"?null:vm.seniorSearchShowing.ruleId,
                valueTitle: vm.seniorSearchShowing.rule
            });
            var emptyStatus = true;
            if (vm.seniorSearchShowing.generate) {
                fields.push({
                    title: "已生成",
                    field: "generated",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
                emptyStatus = false;
            }
            if (vm.seniorSearchShowing.download) {
                fields.push({
                    title: "已下载",
                    field: "download",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
                emptyStatus = false;
            }
            if (vm.seniorSearchShowing.inboard) {
                fields.push({
                    title: "已入库>1%",
                    field: "inboard",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
                emptyStatus = false;
            }
            if (vm.seniorSearchShowing.outboard) {
                fields.push({
                    title: "已出库>1%",
                    field: "outboard",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
                emptyStatus = false;
            }
            if (vm.seniorSearchShowing.active) {
                fields.push({
                    title: "已激活>1%",
                    field: "activated",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
                emptyStatus = false;
            }
            if (vm.seniorSearchShowing.stack) {
                fields.push({
                    title: "已堆垛>1%",
                    field: "stacking",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
                emptyStatus = false;
            }
            if (vm.seniorSearchShowing.cancel) {
                fields.push({
                    title: "已作废>1%",
                    field: "cancelled",
                    type: "BOOLEAN",
                    operator: "EQ",
                    value: true
                });
                emptyStatus = false;
            }
            if (emptyStatus) {
                fields.push({
                    title: "码状态",
                    field: "code_status",
                    type: "TEXT",
                    operator: "EQ",
                    value: null,
                    valueTitle: "全部"
                });
            }

            SmartDocService.create(param, function () {
                MessageService.success("智能菜单保存成功 ！即将刷新页面...");
                setTimeout(function () {
                    window.location.reload();
                },2000);
            }, function (error) {
                MessageService.error("智能菜单保存失败 ！");
            });
        }
    }
})();
