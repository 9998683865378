(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pswreset', {
                parent: 'app',
                url: '/pswreset?resetKey',
                data: {
                    authorities: []
                },
                views: {
                    'index@': {
                        templateUrl: 'app/passwordReset/pswreset.html',
                        controller: 'PswresetController'
                    }
                }
                // resolve: {
                //     translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                //         $translatePartialLoader.addPart('home');
                //         $translatePartialLoader.addPart('module');
                //         return $translate.refresh();
                //     }]
                // }
            })
    }
})();
