(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('UserCreateController',UserCreateController);

    UserCreateController.$inject = ['$state','Principal','User', 'JhiLanguageService','Role','MessageService','Upload','validateInput','$uibModalInstance','$scope','entity','$rootScope'];

    function UserCreateController ($state,Principal, User, JhiLanguageService,Role,MessageService,Upload,validateInput,$uibModalInstance,$scope,entity,$rootScope) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        // vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.isCreateAction = true;
        vm.user = {};
        vm.user.activated = true;
        vm.user.gender = "0";
        vm.user.langKey = "zh-cn";
        vm.user.role = 24;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateOrdered = false;
        vm.openCalendar = openCalendar;
        vm.validateName = validateName;
        vm.validatePwd = validatePwd;
        vm.validateComPwd = validateComPwd;
        vm.validateEmail = validateEmail;
        vm.validatePhone = validatePhone;
        vm.entryInput = true;

        if (entity) {
            vm.isCreateAction = false;
            vm.user = angular.copy(entity);
        }

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        vm.dateOptions = {
            dateDisabled: disabled
        };

        // Disable weekend selection
        function disabled(data) {
            var date = data.date,
                mode = data.mode;
            return mode === 'day' && (date > new Date());
        }

        vm.gender = [
            {name:"保密",code:"0"},
            {name:"男",code:"1"},
            {name:"女",code:"2"}
        ];

        vm.shouldEntryInput = function () {
            vm.entryInput = !vm.entryInput;
            if (vm.entryInput) {
                $("#user_passw")[0].type = "password";
                $("#user_passw_confirm")[0].type = "password";
            } else {
                $("#user_passw")[0].type = "text";
                $("#user_passw_confirm")[0].type = "text";
            }
        };

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });

            Role.getAll({},function(data){
                vm.role = data;
                vm.user.role = vm.isCreateAction?vm.role[0]:vm.role.filter(function (value) {
                    return value.id===vm.user.role.id;
                })[0];
            });
        }

        function openCalendar(){
            vm.datePickerOpenStatus.dateOrdered = true;
        }

        function onSaveSuccess (result) {
            if (vm.isCreateAction) {
                MessageService.success("用户新建成功 !");
            } else {
                MessageService.success("用户编辑成功 !");
            }
            if(vm.user.id === vm.account.id){
                $rootScope.$broadcast('changeUserLogo',vm.url);
            }
            $uibModalInstance.close();
        }

        function onSaveError (error) {
            if (error && error.data && error.data.errmsg) {
                MessageService.error(error.data.errmsg);
            }
            else {
                MessageService.error("请检查用户名(或手机号)和邮箱是否已被使用");
            }
        }

        function save () {
            if(vm.user.role === null || vm.user.role === undefined){
                MessageService.error("请选择角色");
                return;
            }
            vm.user.roleId = vm.user.role.id;
            vm.user.roleName = vm.user.role.name;

            validate();
        }

        function validateName(){
            vm.errorName = validateInput.isUserName(vm.user.login);
        }

        function validateComPwd(){
            vm.errorPwd = (vm.user.confirmpassword !== vm.user.password);
        }

        function validatePwd(){
            vm.errorPwdInput = validateInput.isPassword(vm.user.password);
        }

        function validateEmail(){
            vm.errorEmail = validateInput.isEmail(vm.user.email);
        }

        function validatePhone(){
            vm.errorPhone = validateInput.isPhone(vm.user.phone);
        }

        function validate(){
            var postData = {
                login: vm.user.login,
                fullname:vm.user.fullname,
                department:vm.user.department,
                gender:vm.user.gender,
                dateOfBirth:vm.user.dateOfBirth==null?null:new Date(vm.user.dateOfBirth),
                phone: vm.user.phone,
                email: vm.user.email,
                langKey: "zh-cn",
                roleId: vm.user.roleId != null ? vm.user.roleId : null,
                roleName: vm.user.roleName != null ? vm.user.roleName : null
            };
            if (vm.isCreateAction) {
                postData.passwordHash = vm.user.password;
                User.save(postData, onSaveSuccess, onSaveError);
            } else {
                postData.id = vm.user.id;
                User.update(postData,onSaveSuccess,onSaveError);
            }
        }

        $scope.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        }
    }
})();
