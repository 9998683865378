(function() {
    'use strict';

    angular
        .module('gwApp')
        .factory('Module', Module);

    Module.$inject = ['$resource'];

    function Module ($resource) {
        /*模块的列表配置*/
        // ROLE_CODE_ACTIVATE	激活	生码管理	WEB权限
        // ROLE_CODE_APPEND	补码	生码管理	WEB权限
        // ROLE_CODE_CANCEL	作废	生码管理	WEB权限
        // ROLE_CODE_CREATE	生码	生码管理	WEB权限
        // ROLE_CODE_DOWNLOAD	下载	生码管理	WEB权限
        // ROLE_CODE_EDIT	编辑	生码管理	WEB权限
        // ROLE_CODE_QUERY	码列表查看	生码管理	WEB权限
        // ROLE_CODE_VIEW	查看详情	生码管理	WEB权限

        // ROLE_USER_ADD	新建用户	用户管理	WEB权限
        // ROLE_USER_EDIT	编辑用户	用户管理	WEB权限
        // ROLE_USER_FORBID	禁用用户	用户管理	WEB权限
        // ROLE_USER_VIEW	查看用户	用户管理	WEB权限

        // ROLE_CUSTOMER_ADD	新建分销商	基础数据管理	WEB权限
        // ROLE_CUSTOMER_EDIT	编辑分销商	基础数据管理	WEB权限
        // ROLE_CUSTOMER_FORBID	禁用分销商	基础数据管理	WEB权限
        // ROLE_CUSTOMER_VIEW	查看分销商	基础数据管理	WEB权限

        // ROLE_RULE_ADD	新建生码规则	基础数据管理	WEB权限
        // ROLE_RULE_EDIT	编辑生码规则	基础数据管理	WEB权限
        // ROLE_RULE_FORBID	禁用生码规则	基础数据管理	WEB权限
        // ROLE_RULE_VIEW	查看生码规则	基础数据管理	WEB权限

        // ROLE_ROLE_ADD	新建角色	基础数据管理	WEB权限
        // ROLE_ROLE_EDIT	编辑角色	基础数据管理	WEB权限
        // ROLE_ROLE_FORBID	禁用角色	基础数据管理	WEB权限
        // ROLE_ROLE_VIEW	查看角色	基础数据管理	WEB权限

        // ROLE_PRODUCT_ADD	新建产品	基础数据管理	WEB权限
        // ROLE_PRODUCT_EDIT	编辑产品	基础数据管理	WEB权限
        // ROLE_PRODUCT_FORBID	禁用产品	基础数据管理	WEB权限
        // ROLE_PRODUCT_VIEW	查看产品	基础数据管理	WEB权限

        // ROLE_SUPPLIER_ADD	新建供应商	基础数据管理	WEB权限
        // ROLE_SUPPLIER_EDIT	编辑供应商	基础数据管理	WEB权限
        // ROLE_SUPPLIER_FORBID	禁用供应商	基础数据管理	WEB权限
        // ROLE_SUPPLIER_VIEW	查看供应商	基础数据管理	WEB权限

        var modules = [
            {
                name:"单号管理",
                internalUnit:true,
                icon:"fa fa-file-text",
                id:"order-manager",
                auth:["ROLE_PO_VIEW","ROLE_SO_VIEW"],
                menus:[
                    {
                        name:"采购单号清单",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"purchaseOrder",
                        auth:["ROLE_PO_VIEW"]
                    }
                    // ,{
                    //     name:"销售单号",
                    //     internalUnit:true,
                    //     icon:"fa fa-user",
                    //     id:"saleOrder",
                    //     auth:["ROLE_SO_VIEW"]
                    // }
                    ,{
                        name:"产品发货清单",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"outboardOrder",
                        auth:["ROLE_REPORT_OUTBOARD_PRODUCT"]
                    },{
                        name:"工厂发货清单",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"deliverOrder",
                        auth:["ROLE_REPORT_OUTBOARD_ORDER"]
                    }
                ]
            },{
                name:"生码管理",
                internalUnit:true,
                icon:"fa fa-qrcode",
                id:"codeManager",
                auth:["ROLE_CODE_QUERY","ROLE_SUPPORT_CODE_QUERY"],
                menus:[
                    {
                        name:"码查询",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"codeSearch",
                        auth:["ROLE_SUPPORT_CODE_QUERY"]
                    },
                    {
                        name:"码列表",
                        internalUnit:true,
                        icon:"fa fa-user",
                        uiSref: "code({id:null})",
                        id:"code",
                        auth:["ROLE_CODE_QUERY"]
                    }
                ]
            },{
                name:"用户管理",
                internalUnit:true,
                icon:"fa fa-user",
                id:"userManage",
                auth:["ROLE_USER_VIEW"],
                menus:[
                    {
                        name:"用户列表",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"user",
                        auth:["ROLE_USER_VIEW"]
                    }
                ]
            },
            {
                name:"基础数据管理",
                internalUnit:true,
                icon:"fa fa-database",
                id:"baseManager",
                auth:["ROLE_RULE_VIEW", "ROLE_SUPPLIER_VIEW", "ROLE_CUSTOMER_VIEW", "ROLE_ROLE_VIEW", "ROLE_PRODUCT_VIEW"],
                menus:[
                    {
                        name:"生码规则管理",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"base-coderule",
                        auth:["ROLE_RULE_VIEW"]
                    },
                    {
                        name:"供应商管理",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"base-supplier",
                        auth:["ROLE_SUPPLIER_VIEW"]
                    },
                    {
                        name:"经销商管理",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"base-sale",
                        auth:["ROLE_CUSTOMER_VIEW"]
                    },
                    {
                        name:"物流公司管理",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"base-logistics",
                        auth:["ROLE_LOGISTICS_VIEW"]
                    },
                    {
                        name:"角色权限管理",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"user-role",
                        auth:["ROLE_ROLE_VIEW"]
                    },
                    {
                        name:"产品参数管理",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"base-product",
                        auth:["ROLE_PRODUCT_VIEW"]
                    }
                ]
            },
            {
                name:"系统设置",
                internalUnit:true,
                icon:"fa fa-cog",
                id:"systemSetting",
                auth:["ROLE_USER"],
                menus:[
                    {
                        name:"个人资料",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"loginUserInfo",
                        auth:["ROLE_USER"]
                    },{
                        name:"智能归集菜单",
                        internalUnit:true,
                        icon:"fa fa-user",
                        id:"smartDoc",
                        auth:["ROLE_CODE_QUERY","ROLE_CODE_VIEW"]
                    }
                ]
            }
        ];
        return modules;
    }
})();
