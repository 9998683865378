(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state','MessageService','$uibModal'];

    function HomeController ($scope, Principal, LoginService, $state,MessageService,$uibModal) {
        var vm = this;

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account.data;           // 账号信息
                vm.auth = account.data.authorities;  // 权限列表
                // console.log('auth',vm.auth);
                if (hasAuth('ROLE_CODE_QUERY')||hasAuth('ROLE_CODE_VIEW')) {
                    $state.go('code');
                } else if (hasAuth('ROLE_RULE_VIEW')) {
                    $state.go('base-coderule');
                } else if (hasAuth('ROLE_SUPPORT_CODE_QUERY')) {
                    $state.go('codeSearch');
                } else if (hasAuth('ROLE_SUPPLIER_VIEW')) {
                    $state.go('base-supplier');
                } else if (hasAuth('ROLE_CUSTOMER_VIEW')) {
                    $state.go('base-sale');
                } else if (hasAuth('ROLE_ROLE_VIEW')) {
                    $state.go('user-role');
                } else if (hasAuth('ROLE_PRODUCT_VIEW')) {
                    $state.go('base-product');
                } else if (hasAuth('ROLE_USER_VIEW')) {
                    $state.go('user');
                } else if (hasAuth('ROLE_USER')) {
                    $state.go('loginUserInfo');
                }
            });
        }

        // 判断是否存在对应的权限
        function hasAuth(auth) {
            return vm.auth.indexOf(auth) > -1;
        }
    }
})();
