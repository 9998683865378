(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('Role', Role);

    Role.$inject = ['$resource'];

    function Role ($resource) {
        var resourceUrl =  'qr/api/roles/:operation';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true,
                params: {
                    operation: 'page'
                }
            },
            'list': {
                method: 'get',
                isArray: true,
                params: {
                    operation: 'list'
                }
            },
            'get': {
                method: 'GET',
                params: {
                    operation: 'get'
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'POST',isArray:false,
                params: {
                    operation: 'edit'
                }
            },
            'delete': {
                method: 'POST',
                params: {
                    operation: "delete"
                },
                transformResponse: function (data) {
                    return data;
                }
            },
            'save': { method:'POST',isArray:false,
                params: {
                    operation: 'create'
                }
            },
            'queryFilt': {method:'GET',isArray:true,
                params: {
                    operation: 'queryFilt'
                }
            },
            'getAll': {method:'GET',isArray:true,
                params: {
                    operation: 'getAll'
                }
            }
        });
    }
})();
