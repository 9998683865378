(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('SaleCreateController', SaleCreateController);
    SaleCreateController.$inject = ['$state', '$scope','entity', '$uibModalInstance', 'Address', 'BaseSaleService', 'MessageService'];

    function SaleCreateController($state, $scope,entity, $uibModalInstance, Address, BaseSaleService, MessageService) {
        /**
         * Created by vimpans on 2019/4/30
         */

        if (entity && entity.check) {
            $scope.forbiddenEdit = true;
        }
        $scope.provinceList = Address;
        $scope.cityList = [];
        $scope.areaList = [];
        $scope.initAddress = function (position) {
            $scope.areaList = $scope.cityList = [];
        };
        $scope.selProvinceAtIndex = function (position,index) {
            event.stopPropagation();
            $scope.cityList = $scope.provinceList[index].city;
            if ($scope.addressGroupList[position].province !== $scope.provinceList[index].name) {
                $scope.addressGroupList[position].province = $scope.provinceList[index].name;
                $scope.addressGroupList[position].city
                    = $scope.addressGroupList[position].area = null;
            }
        };
        $scope.selCityAtIndex = function (position,index) {
            event.stopPropagation();
            $scope.areaList = $scope.cityList[index].area;
            if ($scope.addressGroupList[position].city !== $scope.cityList[index].name) {
                $scope.addressGroupList[position].city = $scope.cityList[index].name;
                $scope.addressGroupList[position].area = null;
            }
        };
        $scope.selAreaAtIndex = function (position,index) {
            // event.stopPropagation();
            $scope.addressGroupList[position].area = $scope.areaList[index];
        };

        $scope.addressGroupList = [{province:null,city:null,area:null,detail:null,name:null,contact:null,phone:null}];
        $scope.minusAtIndex = function (idex) {
            if ($scope.addressDefaultIndex === idex) {
                $scope.addressDefaultIndex = 0;
            }
            $scope.addressGroupList.splice(idex,1);
        };
        $scope.plusAction = function () {
            $scope.addressGroupList.push({province:null,city:null,area:null,detail:null,name:null,contact:null,phone:null});
        };
        $scope.addressDefaultIndex = 0;
        $scope.setDefaultAddress = function (idex) {
            $scope.addressDefaultIndex = idex;
        };

        // $scope.contactGroupList = [{name:null,phone:null}];
        // $scope.minusContactAtIndex = function (idex) {
        //     if ($scope.contactDefaultIndex === idex) {
        //         $scope.contactDefaultIndex = 0;
        //     }
        //     $scope.contactGroupList.splice(idex,1);
        // };
        // $scope.plusContactAction = function () {
        //     $scope.contactGroupList.push({name:null,phone:null});
        // };
        // $scope.contactDefaultIndex = 0;
        // $scope.setDefaultContact = function (idex) {
        //     $scope.contactDefaultIndex = idex;
        // };

        $scope.saleEntity = {};

        if (entity && entity.id) {
            BaseSaleService.get({id: entity.id}, function (res) {
                $scope.saleEntity = res.data;
                $scope.addressGroupList = $scope.saleEntity.addresses.map(function (val) {
                    val.area = val.district;
                    val.detail = val.address;
                    return val;
                });
                if (!$scope.addressGroupList || $scope.addressGroupList.length===0){
                    $scope.addressGroupList = [{province:null,city:null,area:null,detail:null,name:null,contact:null,phone:null,primary:true}];
                }
                $scope.addressGroupList.filter(function (value, index) {
                    if (value.primary) {
                        $scope.addressDefaultIndex = index;
                    }
                });
                // $scope.contactGroupList = $scope.saleEntity.contacts;
                // if (!$scope.contactGroupList || $scope.contactGroupList.length===0){
                //     $scope.contactGroupList = [{name:null,phone:null,primary:true}];
                // }
                // $scope.contactGroupList.filter(function (value, index) {
                //     if (value.primary) {
                //         $scope.contactDefaultIndex = index;
                //     }
                // });
            })
        }

        $scope.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };
        function isEffective(a) {
            if (a.province) {return true;}
            if (a.city) {return true;}
            if (a.area) {return true;}
            if (a.detail) {return true;}
            if (a.name) {return true;}
            if (a.contact) {return true;}
            if (a.phone) {return true;}
            return false;
        }
        $scope.saveAction = function () {
            if ($scope.nameExists) {return;}

            var uniqueList = [];
            for(var i=0; i<$scope.addressGroupList.length; i++) {
                var a = $scope.addressGroupList[i];
                var effect = isEffective(a);
                if (effect && !a.name) {
                    MessageService.error('仓库地址 第'+(i+1)+'条，仓库名为必填字段。');
                    return;
                }
                if (uniqueList.indexOf(a.name) === -1) {
                    uniqueList.push(a.name);
                } else {
                    MessageService.error('仓库地址 第'+(i+1)+'条，仓库名重复。');
                    return;
                }
                if (effect && !a.detail) {
                    MessageService.error('仓库地址 第'+(i+1)+'条，详细地址为必填字段。');
                    return;
                }
            }
            // for(var j=0; j<$scope.contactGroupList.length; j++) {
            //     var c = $scope.contactGroupList[j];
            //     if (c.name&&!c.phone) {
            //         MessageService.error('联系人 第'+(j+1)+'条，电话为必填字段。');
            //         return;
            //     } else if (!c.name&&c.phone) {
            //         MessageService.error('联系人 第'+(j+1)+'条，姓名为必填字段。');
            //         return;
            //     }
            // }

            var addresses = $scope.addressGroupList.map(function (value,index) {
                // if (value)
                return {
                    province: value.province,
                    city: value.city,
                    district: value.area,
                    address: value.detail,
                    name: value.name,
                    contact: value.contact,
                    phone: value.phone,
                    primary: $scope.addressDefaultIndex === index
                };
            });
            // var contacts = $scope.contactGroupList.map(function (value,index) {
            //     return {
            //         name: value.name,
            //         phone: value.phone,
            //         primary: $scope.contactDefaultIndex === index
            //     };
            // });
            $scope.saleEntity.addresses = addresses.filter(function (val) {
                return val.address;
            });
            // $scope.saleEntity.contacts = contacts.filter(function (val) {
            //     return val.name&&val.phone;
            // });
            if ($scope.saleEntity && $scope.saleEntity.id) {
                BaseSaleService.edit($scope.saleEntity,function (data) {
                    MessageService.success("编辑成功!");
                    $uibModalInstance.close();
                },function (error) {
                    MessageService.error("编辑失败!");
                });
            } else {
                BaseSaleService.create($scope.saleEntity,function (data) {
                    MessageService.success("创建成功!");
                    $uibModalInstance.close();
                },function (error) {
                    MessageService.error("创建失败!");
                });
            }
        };

        $scope.timeout = null;
        $scope.nameChanging = function (name) {
            $scope.nameExists = false;
            if ($scope.timeout) {
                clearTimeout($scope.timeout);
            }
            $scope.timeout = setTimeout(function () {
                checkField(name);
            },1500);
        };
        function checkField(name) {
            if (!name) {
                return;
            }
            var param = {
                name:'name',
                value: name,
                ref: $scope.saleEntity.id
            };
            BaseSaleService.checkField(param, function (res) {
                $scope.nameExists = res.data.exists;
            });
        }
    }
})();
