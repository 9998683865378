(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeRuleController', CodeRuleController);
    CodeRuleController.$inject = ['$state', '$scope', '$uibModal', 'BaseCodeRuleService', 'MessageService'];

    function CodeRuleController($state, $scope, $uibModal, BaseCodeRuleService, MessageService) {
        /**
         * Created by vimpans on 2019/4/30
         */

        var vm = $scope;

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };

        initDataAction();
        function initDataAction() {
            BaseCodeRuleService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                sort:"id,desc",
                keyword: vm.pageparams.keyword
            },function (res) {
                vm.ruleList = res.data;
                vm.pageparams.count = res.info.count;
            });
        }
        $scope.pageChangeAction = function () {
            initDataAction();
        };
        vm.keyupAction = function (e) {
            if (e.keyCode === 13) {
                vm.searchAction();
            }
        };
        vm.searchAction = function () {
            vm.pageparams.page = 1;
            initDataAction();
        };

        vm.statusSwitch = function (id, status, e) {
            if (status) {
                //disable action
                MessageService.confirm("确定停用该码规则？",function(){
                    BaseCodeRuleService.disable({id:id},function () {
                        initDataAction();
                    },function (error) {
                        console.log('disable action error:',error);
                        MessageService.error('操作失败！请重试');
                        e.target.checked = true;
                    });
                },function () {
                    e.target.checked = true;
                });
            } else {
                //enable action
                BaseCodeRuleService.enable({id:id},function () {
                    initDataAction();
                },function (error) {
                    console.log('enable action error:',error);
                    MessageService.error('操作失败！请重试');
                    e.target.checked = false;
                });
            }
        };

        $scope.createCodeRuleModal = function () {
            $uibModal.open({
                animation: true,
                backdrop: 'static',
                keyboard: false,
                templateUrl: 'app/base-manager/base-coderule/code-rule-create.html',
                controller: 'CodeRuleCreateController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {
                initDataAction();
            });
        };
        vm.editRuleAction = function (id,check,batchCounter) {
            if(batchCounter !== 0) {
                MessageService.confirm("该码规则已被使用，无法直接编辑，是否继续编辑并另存，生成新的码规则？",function () {
                    $uibModal.open({
                        animation: true,
                        backdrop: 'static',
                        keyboard: false,
                        templateUrl: 'app/base-manager/base-coderule/code-rule-create.html',
                        controller: 'CodeRuleCreateController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {id:id,check:check};
                            }
                        }
                    }).result.then(function () {
                        initDataAction();
                    });
                });
            } else {
                $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    keyboard: false,
                    templateUrl: 'app/base-manager/base-coderule/code-rule-create.html',
                    controller: 'CodeRuleCreateController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {id:id,check:check};
                        }
                    }
                }).result.then(function () {
                    initDataAction();
                });
            }

        };
    }
})();
