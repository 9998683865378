(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('CodeBatchService', CodeBatchService)
        .factory('CodeItemService', CodeItemService)
        .factory('CodeItemDetailService', CodeItemDetailService)
        .factory('SmartDocService', SmartDocService);

    CodeBatchService.$inject = ['$resource'];
    CodeItemService.$inject = ['$resource'];
    CodeItemDetailService.$inject = ['$resource'];
    SmartDocService.$inject = ['$resource'];

    function CodeBatchService($resource) {
        var service = $resource('qr/api/batches/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false},
            'search': {method: 'POST', params: {param: 'search'}, isArray: false},
            'generateStatus': {method: 'GET', params: {param: 'generateStatus'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'edit': {method: 'POST', params: {param: 'edit'}, isArray: false},
            'get': {method: 'GET', params: {param: 'get'}, isArray: false},
            'calcAppendNext': {method: 'GET', params: {param: 'calcAppendNext'}, isArray: false},
            'calcAppendEnd': {method: 'GET', params: {param: 'calcAppendEnd'}, isArray: false},
            'retryGenerate': {method: 'POST', params: {param: 'retryGenerate'}, isArray: false},
            'delete': {method: 'POST', params: {param: 'delete'}, isArray: false},
            'append': {method: 'POST', params: {param: 'append'}, isArray: false},
            'cancelRange': {method: 'POST', params: {param: 'cancelRange'}, isArray: false},
            'cancelCode': {method: 'POST', params: {param: 'cancelCode'}, isArray: false},
            'cancelUnused': {method: 'POST', params: {param: 'cancelUnused'}, isArray: false},
            'activateRange': {method: 'POST', params: {param: 'activateRange'}, isArray: false},
            'revokeActivateRange': {method: 'POST', params: {param: 'revokeActivateRange'}, isArray: false},
            'download': {
                method: 'POST',
                params: {param: 'download'},
                isArray: false,
                responseType: 'blob',
                transformResponse: function (data, hg) {
                    var filename = hg()['content-disposition'];
                    if (filename) {
                        var temp = filename.split('=');
                        filename = temp[temp.length - 1];
                    }
                    return {
                        blob: data,
                        filename: filename
                    };
                }
            }
            // arraybuffer
        });
        return service;
    }
    function CodeItemService($resource) {
        var service = $resource('qr/api/codeStore/:param', {}, {
            'findByCode': {method: 'GET', params: {param: 'findByCode'}, isArray: false}
        });
        return service;
    }
    function CodeItemDetailService($resource) {
        var service = $resource('qr/api/PDA/:param', {}, {
            'findDetails': {method: 'GET', params: {param: 'findDetails'}, isArray: false}
        });
        return service;
    }
    function SmartDocService($resource) {
        var service = $resource('qr/api/smartdoc/:param', {}, {
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'getAll': {method: 'GET', params: {param: 'getAll'}, isArray: false},
            'findOne': {method: 'GET', params: {param: 'get'}, isArray: false},
            'delete': {method: 'POST', params: {param: 'delete'}, isArray: false}
        });
        return service;
    }
})();
