(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeEditController', CodeEditController);
    CodeEditController.$inject = ['$state', '$scope', 'entity', '$uibModalInstance', 'MessageService', 'CodeBatchService', 'BaseCodeRuleService', '$uibModal'];

    function CodeEditController($state, $scope ,entity , $uibModalInstance, MessageService, CodeBatchService, BaseCodeRuleService, $uibModal) {
        /**
         * Created by vimpans on 2019/5/8
         */
        var vm = $scope;

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        if (!entity && !entity.id) {
            vm.dismissModalAction();
            MessageService.error('错误的生码记录 !');
        }

        init();
        function init() {
            vm.codeCategory = [];
            BaseCodeRuleService.segmentTypes({},function (res) {
                vm.codeCategory = res.data.map(function (value) {
                    value.options = [];
                    return value;
                });

                CodeBatchService.get({id:entity.id}, function (res) {
                    vm.code = res.data;
                    vm.code.levelName = vm.code.level==0?'产品码':'垛码';
                    vm.code.codeType = vm.code.barcode?(vm.code.qrcode?'一维码，二维码':'一维码'):(vm.code.qrcode?'二维码':'');
                    vm.codeSegments = vm.code.segments.sort(function (a, b) {
                        return a.seq-b.seq;
                    });
                    vm.appendableQuantity = (vm.code.quantityLimits||99999)-(vm.code.generatedQuantity||0);
                    vm.appendingInProgress = false;
                    initCodeSegments();
                });
            });
            vm.append = {};
            CodeBatchService.calcAppendNext({id: entity.id}, function (res) {
                vm.codePrimarySerial = res.data.serial;
            });
        }

        //补码
        vm.timeout = null;
        vm.calcAppendEndSearch = function () {
            vm.append.quantity = vm.append.quantity > vm.code.quantityLimits ? vm.code.quantityLimits : vm.append.quantity;
            if (vm.timeout) {
                clearTimeout(vm.timeout);
            }
            if (!vm.append.quantity) {return}
            vm.timeout = setTimeout(function () {
                CodeBatchService.calcAppendEnd({id: entity.id, quantity: vm.append.quantity}, function (res) {
                    vm.append.codeValue = res.data.codeValue;
                });
            },500);
        };
        vm.appendCodeAction = function () {
            MessageService.confirm("确定补"+vm.append.quantity+vm.code.unit+"码？",function(){
                CodeBatchService.append({id: entity.id,appendQuantity: vm.append.quantity},function (res) {
                    // checkResult('补码',res.data,function () {
                    //     vm.append = {};
                    // })
                    MessageService.success("补码成功 ！");
                    vm.appendingInProgress = true;

                    var nextSerial = parseInt(vm.codePrimarySerial);
                    nextSerial += vm.append.quantity;
                    vm.codePrimarySerial = nextSerial.toString();
                    vm.appendableQuantity -= vm.append.quantity;
                    if (vm.appendableQuantity < 0) {
                        vm.appendableQuantity = 0;
                    }
                    vm.append = {};
                },function (error) {
                    console.log('append code fail:',error);
                    MessageService.error('补码失败，请重试 ！' + (error.title||''));
                });
            });
        };
        //作废码
        vm.cancelRange = {};
        vm.cancelRangeAction = function () {

            // if (vm.cancelRange.to <= vm.cancelRange.from) {
            //     MessageService.error('作废范围不得为空 !');
            //     return;
            // }
            //
            // var size = vm.code.startSerial.length;
            // if (vm.cancelRange.to.length!==size || vm.cancelRange.from.length!==size) {
            //     MessageService.error('作废范围位数不满足'+size+'位 !');
            //     return;
            // }

            MessageService.confirm("确定作废"+(vm.cancelRange.to-vm.cancelRange.from + 1)+vm.code.unit+"码？",function(){
                vm.cancelRange.id = entity.id;
                CodeBatchService.cancelRange(vm.cancelRange, function (res) {
                    checkResult('作废码',res.data,function () {
                        vm.cancelRange = {};
                    })
                }, function (error) {
                    console.log('cancel code fail:',error);
                    MessageService.error('作废码失败，请重试 ！');
                });
            });

        };
        vm.cancelUnusedAction = function () {

            if (!vm.cancelRange.reason) {
                MessageService.error('请填写作废原因 ！');
                return;
            }

            MessageService.confirm("确定作废所有未入库的码？",function(){
                vm.cancelRange.id = entity.id;
                CodeBatchService.cancelUnused(vm.cancelRange, function (res) {
                    checkResult('作废码',res.data,function () {
                        vm.cancelRange = {};
                    })
                }, function (error) {
                    console.log('cancel code fail:',error);
                    MessageService.error('作废码失败，请重试 ！');
                });
            });
        };

        //激活码
        vm.activateRange = {};
        vm.activateRangeAction = function () {

            // if (vm.activateRange.to <= vm.activateRange.from) {
            //     MessageService.error('激活范围不得为空 !');
            //     return;
            // }

            MessageService.confirm("确定激活"+(vm.activateRange.to-vm.activateRange.from + 1)+vm.code.unit+"码？",function(){
                vm.activateRange.id = entity.id;
                CodeBatchService.activateRange(vm.activateRange, function (res) {
                    checkResult('激活码',res.data,function () {
                        vm.activateRange = {};
                    })
                }, function (error) {
                    console.log('active code fail:',error);
                    MessageService.error('激活码失败，请重试 ！');
                });
            });

        };
        vm.revokeActivateRangeAction = function () {

            // if (vm.activateRange.to <= vm.activateRange.from) {
            //     MessageService.error('取消激活范围不得为空 !');
            //     return;
            // }

            MessageService.confirm("确定取消激活"+(vm.activateRange.to-vm.activateRange.from + 1)+vm.code.unit+"码？",function(){
                vm.activateRange.id = entity.id;
                CodeBatchService.revokeActivateRange(vm.activateRange, function (res) {
                    checkResult('取消激活码',res.data,function () {
                        vm.activateRange = {};
                    })
                }, function (error) {
                    console.log('revoke active code fail:',error);
                    MessageService.error('取消激活码失败，请重试 ！');
                });
            });

        };


        vm.codeSegments = [];
        function initCodeSegments() {
            for (var i=0; i<vm.codeSegments.length; i++) {
                var codeInfo = vm.codeSegments[i];
                var t = typePosition(codeInfo.type);
                var type = vm.codeCategory[t];
                codeInfo.typeIndex = t;
                codeInfo.typeName = type.name;
                valueTypePosition(i);
            }
        }
        function typePosition(type) {
            var index = null;
            for (var i=0; i<vm.codeCategory.length; i++) {
                if (vm.codeCategory[i].value === type) {
                    index = i;
                }
            }
            return index;
        }
        function valueTypePosition(index) {
            var info = vm.codeSegments[index];
            var cc = vm.codeCategory[info.typeIndex];
            if (!cc.options || cc.options.length===0) {
                BaseCodeRuleService.segmentOptions({type:cc.value},function (res) {
                    cc.options = res.data;
                    for (var i=0; i<cc.options.length; i++) {
                        var valueType = cc.options[i];
                        if (valueType.key === info.valueType) {
                            info.valueType = valueType.key;
                            info.valueTypeIndex = i;
                            info.valueTypeName = valueType.name;
                            info.valueSource = valueType.source;
                        }
                    }
                });
            } else {
                for (var i=0; i<cc.options.length; i++) {
                    var valueType = cc.options[i];
                    if (valueType.key === info.valueType) {
                        info.valueType = valueType.key;
                        info.valueTypeIndex = i;
                        info.valueTypeName = valueType.name;
                        info.valueSource = valueType.source;
                    }
                }
            }
        }

        function checkResult(msg, res, cb) {
            console.log('check',msg);
            if (res && res.success) {
                if (res.appliedCount == 0) {
                    MessageService.error('共有'+res.appliedCount+(vm.code.unit||'个')+'码被处理，' + msg + "失败");
                }
                else {
                    MessageService.success(msg+'成功，一共处理了'+res.appliedCount+(vm.code.unit||'个')+'码。');
                }
                cb();
            } else {
                MessageService.error(res.message);
            }
        }

        vm.editAction = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/code/code-s-edit.html',
                controller: 'CodeSEditController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return angular.copy(vm.code);
                    }
                }
            }).result.then(function () {
                init();
            });
        }

    }
})();
