(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('SupplierCreateController', SupplierCreateController);
    SupplierCreateController.$inject = ['$state', '$scope','entity', '$uibModalInstance', 'Address', 'BaseSupplierService', 'MessageService', 'BaseSaleService'];

    function SupplierCreateController($state, $scope,entity, $uibModalInstance, Address, BaseSupplierService, MessageService, BaseSaleService) {
        /**
         * Created by vimpans on 2019/4/30
         */
        if (entity && entity.check) {
            $scope.forbiddenEdit = true;
        }
        $scope.provinceList = Address;
        $scope.cityList = [];
        $scope.areaList = [];
        $scope.initAddress = function (position) {
            $scope.areaList = $scope.cityList = [];
        };
        $scope.selProvinceAtIndex = function (position,index) {
            event.stopPropagation();
            $scope.cityList = $scope.provinceList[index].city;
            if ($scope.addressGroupList[position].province !== $scope.provinceList[index].name) {
                $scope.addressGroupList[position].province = $scope.provinceList[index].name;
                $scope.addressGroupList[position].city
                    = $scope.addressGroupList[position].area = null;
            }
        };
        $scope.selCityAtIndex = function (position,index) {
            event.stopPropagation();
            $scope.areaList = $scope.cityList[index].area;
            if ($scope.addressGroupList[position].city !== $scope.cityList[index].name) {
                $scope.addressGroupList[position].city = $scope.cityList[index].name;
                $scope.addressGroupList[position].area = null;
            }
        };
        $scope.selAreaAtIndex = function (position,index) {
            // event.stopPropagation();
            $scope.addressGroupList[position].area = $scope.areaList[index];
        };
        $scope.addressGroupList = [{province:null,city:null,area:null,detail:null}];
        $scope.minusAtIndex = function (idex) {
            if ($scope.addressDefaultIndex === idex) {
                $scope.addressDefaultIndex = 0;
            }
            $scope.addressGroupList.splice(idex,1);
        };
        $scope.plusAction = function () {
            $scope.addressGroupList.push({province:null,city:null,area:null,detail:null});
        };
        $scope.addressDefaultIndex = 0;
        $scope.setDefaultAddress = function (idex) {
            $scope.addressDefaultIndex = idex;
        };

        $scope.contactGroupList = [{name:null,phone:null}];
        $scope.minusContactAtIndex = function (idex) {
            if ($scope.contactDefaultIndex === idex) {
                $scope.contactDefaultIndex = 0;
            }
            $scope.contactGroupList.splice(idex,1);
        };
        $scope.plusContactAction = function () {
            $scope.contactGroupList.push({name:null,phone:null});
        };
        $scope.contactDefaultIndex = 0;
        $scope.setDefaultContact = function (idex) {
            $scope.contactDefaultIndex = idex;
        };

        $scope.supplierEntity = {};

        if (entity && entity.id) {
            BaseSupplierService.get({id: entity.id}, function (res) {
                $scope.supplierEntity = res.data;
                $scope.addressGroupList = $scope.supplierEntity.addresses.map(function (val) {
                    val.area = val.district;
                    val.detail = val.address;
                    return val;
                });
                if (!$scope.addressGroupList || $scope.addressGroupList.length===0){
                    $scope.addressGroupList = [{province:null,city:null,area:null,detail:null,primary:true}];
                }
                $scope.addressGroupList.filter(function (value, index) {
                    if (value.primary) {
                        $scope.addressDefaultIndex = index;
                    }
                });
                $scope.contactGroupList = $scope.supplierEntity.contacts;
                if (!$scope.contactGroupList || $scope.contactGroupList.length===0){
                    $scope.contactGroupList = [{name:null,phone:null,primary:true}];
                }
                $scope.contactGroupList.filter(function (value, index) {
                    if (value.primary) {
                        $scope.contactDefaultIndex = index;
                    }
                });
            })
        }

        $scope.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        $scope.saveAction = function () {
            if ($scope.nameExists) {return;}

            for(var i=0; i<$scope.addressGroupList.length; i++) {
                var a = $scope.addressGroupList[i];
                if ((a.province||a.city||a.area) && !a.detail) {
                    MessageService.error('地址 第'+i+'条，缺少必填字段。');
                    return;
                }
            }
            for(var j=0; j<$scope.contactGroupList.length; j++) {
                var c = $scope.contactGroupList[j];
                if (c.name&&!c.phone) {
                    MessageService.error('联系人 第'+(j+1)+'条，电话为必填字段。');
                    return;
                } else if (!c.name&&c.phone) {
                    MessageService.error('联系人 第'+(j+1)+'条，姓名为必填字段。');
                    return;
                }
            }

            var addresses = $scope.addressGroupList.map(function (value,index) {
                return {
                    province: value.province,
                    city: value.city,
                    district: value.area,
                    address: value.detail,
                    primary: $scope.addressDefaultIndex === index
                };
            });
            var contacts = $scope.contactGroupList.map(function (value,index) {
                return {
                    name: value.name,
                    phone: value.phone,
                    primary: $scope.contactDefaultIndex === index
                };
            });
            $scope.supplierEntity.addresses =  addresses.filter(function (val) {
                return val.address;
            });
            $scope.supplierEntity.contacts = contacts.filter(function (val) {
                return val.name&&val.phone;
            });

            if ($scope.supplierEntity && $scope.supplierEntity.id) {
                BaseSupplierService.edit($scope.supplierEntity,function (data) {
                    MessageService.success("编辑成功!");
                    $uibModalInstance.close();
                },function (error) {
                    MessageService.error("编辑失败!");
                });
            } else {
                BaseSupplierService.create($scope.supplierEntity,function (data) {
                    MessageService.success("创建成功!");
                    $uibModalInstance.close();
                },function (error) {
                    MessageService.error("创建失败!");
                });
            }
        };

        $scope.timeout = null;
        $scope.nameChanging = function (name) {
            $scope.nameExists = false;
            if ($scope.timeout) {
                clearTimeout($scope.timeout);
            }
            $scope.timeout = setTimeout(function () {
                checkField(name);
            },1500);
        };
        function checkField(name) {
            if (!name) {
                return;
            }
            var param = {
                name:'name',
                value: name,
                ref: $scope.supplierEntity.id
            };
            BaseSupplierService.checkField(param, function (res) {
                $scope.nameExists = res.data.exists;
            });
        }
    }
})();
