(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('purchaseOrder', {
                parent: 'module',
                url: '/purchase-order',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/order-manager/purchase-order.html',
                        controller: 'PurchaseOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('saleOrder', {
                parent: 'module',
                url: '/sale-order',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/order-manager/sale-order.html',
                        controller: 'SaleOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('outboardOrder', {
                parent: 'module',
                url: '/outboard-order',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/order-manager/outboard-order.html',
                        controller: 'OutboardOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('deliverOrder', {
                parent: 'module',
                url: '/deliver-order',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/order-manager/deliver-order.html',
                        controller: 'DeliverOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
