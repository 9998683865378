(function() {
    'use strict';
    angular
        .module('gwApp')
        .factory('CompanyAccount', CompanyAccount);

    CompanyAccount.$inject = ['$resource'];

    function CompanyAccount ($resource) {
        var resourceUrl =  'qr/api/account/:operation';

        return $resource(resourceUrl, {}, {
            'reset': {
                method: 'GET',
                params:{
                    operation:'reset_password'
                }
            }
        });
    }
})();
