(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('AppLoginController', AppLoginController);

    AppLoginController.$inject = ['$rootScope','$scope' ,'$state', '$timeout', 'Auth','MessageService'];

    function AppLoginController ($rootScope,$scope ,$state, $timeout, Auth,MessageService) {
        var vm = this;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.rememberMe = false;
        vm.username = null;

        $timeout(function (){angular.element('#username').focus();});


        function login () {
            // event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                } else {
                    $state.go('home');
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        $scope.$on("loginError", function(e, m) {
            vm.errorMes = "您填写的帐号或密码不正确";
        })

    }
})();
