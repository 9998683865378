(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeDownloadController', CodeDownloadController);
    CodeDownloadController.$inject = ['$state', '$scope', '$uibModalInstance', 'entity', 'MessageService', 'BaseCodeRuleService', 'CodeBatchService'];

    function CodeDownloadController($state, $scope, $uibModalInstance, entity, MessageService, BaseCodeRuleService, CodeBatchService) {
        /**
         * Created by vimpans on 2019/5/9
         */
        var vm = $scope;

        vm.downloading = false;

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        if (!entity && !entity.id) {
            vm.dismissModalAction();
            MessageService.error('错误的生码记录 !');
        }

        init();
        function init() {
            vm.codeCategory = [];
            BaseCodeRuleService.segmentTypes({},function (res) {
                vm.codeCategory = res.data.map(function (value) {
                    value.options = [];
                    return value;
                });
                CodeBatchService.get({id:entity.id}, function (res) {
                    vm.code = res.data;
                    vm.code.levelName = vm.code.level==0?'产品码':'垛码';
                    vm.code.codeType = vm.code.barcode?(vm.code.qrcode?'一维码，二维码':'一维码'):(vm.code.qrcode?'二维码':'');
                    vm.codeSegments = vm.code.segments.sort(function (a, b) {
                        return a.seq-b.seq;
                    });
                    initCodeSegments();
                });
            });
        }

        //下载码
        vm.downloadModel = {type:'EXCELX'};
        vm.downloadAction = function () {

            if (vm.downloading) {
                MessageService.error('下载中，请勿重复操作!');
                return;
            }
            vm.downloading = true;

            vm.downloadModel.id = entity.id;
            CodeBatchService.download(vm.downloadModel, function (res) {
                MessageService.success('下载中...');
                vm.downloadModel = {type:'EXCELX'};
                download(res.blob, res.filename);
                vm.downloading = false;
            }, function (error) {
                if (error.data && error.data.blob && error.data.blob.type === 'application/json') {
                    var data = error.data.blob;
                    var reader = new FileReader();
                    reader.onload = function (ev) {
                        var jsonString = ev.target.result;
                        var json = JSON.parse(jsonString);
                        console.log('download action fail:', json);
                        MessageService.error('下载出错，'+((json.message+'，')||'')+'请重试!');
                    };
                    reader.readAsText(data,'UTF-8');
                } else {
                    MessageService.error('下载出错,请重试!');
                }
                vm.downloading = false;
            });
        };
        // vm.timeout = null;
        vm.checkDownloadPWD = function () {
            if (vm.downloadModel.password && vm.downloadModel.password.length < 8) {
                vm.downloadModel.error = 'error_length';
            } else if (vm.downloadModel.confirm && vm.downloadModel.confirm!==vm.downloadModel.password) {
                vm.downloadModel.error = 'error_confirm';
            } else {
                vm.downloadModel.error = null
            }
        };
        function download(blob,fileName) {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            //TODO 火狐浏览器兼容
            // document.body.appendChild(a);
            a.click();
            //TODO 火狐浏览器兼容
            // document.body.removeChild(a);
        }

        vm.codeSegments = [];
        function initCodeSegments() {
            for (var i=0; i<vm.codeSegments.length; i++) {
                var codeInfo = vm.codeSegments[i];
                var t = typePosition(codeInfo.type);
                var type = vm.codeCategory[t];
                codeInfo.typeIndex = t;
                codeInfo.typeName = type.name;
                valueTypePosition(i);
            }
        }
        function typePosition(type) {
            var index = null;
            for (var i=0; i<vm.codeCategory.length; i++) {
                if (vm.codeCategory[i].value === type) {
                    index = i;
                }
            }
            return index;
        }
        function valueTypePosition(index) {
            var info = vm.codeSegments[index];
            var cc = vm.codeCategory[info.typeIndex];
            if (!cc.options || cc.options.length===0) {
                BaseCodeRuleService.segmentOptions({type:cc.value},function (res) {
                    cc.options = res.data;
                    for (var i=0; i<cc.options.length; i++) {
                        var valueType = cc.options[i];
                        if (valueType.key === info.valueType) {
                            info.valueType = valueType.key;
                            info.valueTypeIndex = i;
                            info.valueTypeName = valueType.name;
                            info.valueSource = valueType.source;
                        }
                    }
                });
            } else {
                for (var i=0; i<cc.options.length; i++) {
                    var valueType = cc.options[i];
                    if (valueType.key === info.valueType) {
                        info.valueType = valueType.key;
                        info.valueTypeIndex = i;
                        info.valueTypeName = valueType.name;
                        info.valueSource = valueType.source;
                    }
                }
            }
        }
    }
})();
