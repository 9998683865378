(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeSEditController', CodeSEditController);
    CodeSEditController.$inject = ['$state', '$scope', 'entity', '$uibModalInstance', 'MessageService', 'BaseSupplierService', 'PurchaseOrderService', 'CodeBatchService'];

    function CodeSEditController($state, $scope, entity, $uibModalInstance, MessageService, BaseSupplierService, PurchaseOrderService, CodeBatchService) {
        /**
         * Created by vimpans on 2019/5/10
         */
        var vm = $scope;

        vm.codeModel = entity;

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.supplierList = [];
        vm.searchSupplierAction = function (val) {
            BaseSupplierService.queryAvailable({keyword:val},function (res) {
                vm.supplierList = res.data;
            });
        };

        vm.purchaseOrderList = [];
        vm.searchPurchaseOrderAction = function (val) {
            PurchaseOrderService.query({keyword: val}, function (res) {
                vm.purchaseOrderList = res.data;
            });
        };

        $scope.mutualIndex = function (data,index,event){
            if (index === 1) {
                vm.codeModel.purchaseOrderNumber = data.number;
                vm.codeModel.purchaseOrderId = data.id;
            } else if (index === 0) {
                vm.codeModel.supplierId = data.id;
                vm.codeModel.supplierName = data.name;
            }
        };

        vm.submitAction = function () {
            var postData = {
                id: vm.codeModel.id,
                supplierId: vm.codeModel.supplierId
            };
            if (vm.codeModel.level == 0) {
                postData.purchaseOrderNumber = vm.codeModel.purchaseOrderNumber;
                postData.purchasedQuantity = vm.codeModel.purchasedQuantity;
            }

            CodeBatchService.edit(postData, function (res) {
                MessageService.success('修改成功!');
                $uibModalInstance.close();
            }, function (error) {
                console.log('code edit fail:', error);
                MessageService.error('提交失败 !'+error.message||'');
            });
        };

    }
})();
