(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('BaseSupplierService', BaseSupplierService);

    BaseSupplierService.$inject = ['$resource'];

    function BaseSupplierService($resource) {
        var service = $resource('qr/api/suppliers/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false},
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'edit': {method: 'POST', params: {param: 'edit'}, isArray: false},
            'get': {method: 'GET', params: {param: 'get'}, isArray: false},
            'enable': {method: 'POST', params: {param: 'enable'}, isArray: false},
            'disable': {method: 'POST', params: {param: 'disable'}, isArray: false},
            'checkField': {method: 'GET', params: {param: 'checkField'}, isArray: false},
            'queryAvailable': {method: 'GET', params: {param: 'queryAvailable'}, isArray: false}
        });
        return service;
    }
})();
