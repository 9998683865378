(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeDeleteModalController', CodeDeleteModalController);
    CodeDeleteModalController.$inject = ['$state', '$scope', '$uibModalInstance', 'CodeBatchService', 'MessageService', 'entity'];

    function CodeDeleteModalController($state, $scope, $uibModalInstance, CodeBatchService, MessageService, entity) {
        /**
         * Created by vimpans on 2019/5/13
         */
        var vm = $scope;
        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.cancelModel = {
            id: entity.id,
            reason: null
        };

        vm.saveAction = function () {
            CodeBatchService.delete(vm.cancelModel, function (res) {
                MessageService.success('删除批码成功 !');
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error('删除批码失败 !' + (error.message||''));
            });
        }
    }
})();
