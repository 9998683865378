(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ResetPasswordController', ResetPasswordController);

    ResetPasswordController.$inject = ['Auth', 'Principal','$uibModalInstance','entity','User','$state','MessageService'];

    function ResetPasswordController (Auth, Principal,$uibModalInstance,entity,User,$state,MessageService) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.error = null;
        vm.cancel = cancel;
        vm.success = null;
        vm.login = entity.login;
        vm.user = entity;

        vm.validatePwd = validatePwd;
        vm.validateComPwd = validateComPwd;


        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function validateComPwd(){
            if(vm.confirmpassword!=vm.password){
                vm.errorPwd = true;
            }else{
                vm.errorPwd = false;
            }

        }

        function validatePwd(){
            if (!vm.password) {return}
            if(vm.password.match("^[_'.@A-Za-z0-9-]{8,20}$")==null){
                vm.errorPwdInput = true;
                return;
            }else{
                vm.errorPwdInput = false;
                return;
            }
        }

        function changePassword () {
            var postData = {
                id:vm.user.id,
                password:vm.password
            };
            User.reset(postData,function(data){
                vm.error = null;
                vm.success = 'OK';
                $uibModalInstance.close();
                MessageService.success('密码重置成功，新密码已经发送至邮箱！');
            });
        }

        function cancel (){
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
