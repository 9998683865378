(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('CodeCreateController', CodeCreateController);
    CodeCreateController.$inject = ['$state', '$scope', '$uibModalInstance', 'BaseSupplierService', 'BaseCodeRuleService', 'MessageService', 'CodeBatchService', 'BaseProductService', 'BaseBatchNOService', 'BaseProductCategoryService'];

    function CodeCreateController($state, $scope, $uibModalInstance, BaseSupplierService, BaseCodeRuleService, MessageService, CodeBatchService, BaseProductService, BaseBatchNOService, BaseProductCategoryService) {
        /**
         * Created by vimpans on 2019/5/7
         */
        var vm = $scope;

        vm.codeModel = {
            level: 0,
            rebuilt: false,
            levelName: '产品码'
        };
        initDataAction();
        function initDataAction() {
            vm.codeCategory = [];
            vm.batchDefaultList = [];
            vm.ruleList=[];
            BaseCodeRuleService.segmentTypes({},function (res) {
                vm.codeCategory = res.data.map(function (value) {
                    value.options = [];
                    return value;
                });
            });
            BaseBatchNOService.query({},function (res) {
                vm.batchDefaultList = res.data.available;
            });
            // BaseCodeRuleService.queryAvailable({keyword:null,level:vm.codeModel.level},function (res) {
            //     vm.ruleList = res.data;
            // });
        }

        vm.productList = [];
        vm.productSearch = function (val) {
            var category = null;
            for (var i=0; i<vm.codeSegments.length; i++) {
                var info = vm.codeSegments[i];
                if (info.valueType === 'ALPHABET_CATEGORY') {
                    category = info.primaryValue;
                }
            }
            BaseProductService.queryAvailable({keyword:val,category:category,rebuilt:vm.codeModel.rebuilt},function (res) {
                vm.productList = res.data;
            });
        };
        vm.productCategoryList = [];
        vm.productCategorySearch = function (val) {
            BaseProductCategoryService.query({keyword:val,rebuilt:vm.codeModel.rebuilt},function (res) {
                vm.productCategoryList = res.data;
            });
        };

        vm.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };
        vm.searchSupplierAction = function (val) {
            BaseSupplierService.queryAvailable({keyword:val},function (res) {
                vm.supplierList = res.data;
            });
        };
        vm.searchRuleAction = function (val) {
            BaseCodeRuleService.queryAvailable({keyword:val,level:vm.codeModel.level},function (res) {
                vm.ruleList = res.data;
            });
        };

        vm.codeLevelModel = [{name:'产品码',value:0},{name:'垛码',value:1}];
        vm.codeSegments = [];
        function initCodeSegments() {
            for (var i=0; i<vm.codeSegments.length; i++) {
                var codeInfo = vm.codeSegments[i];
                var t = typePosition(codeInfo.type);
                var type = vm.codeCategory[t];
                codeInfo.typeIndex = t;
                codeInfo.typeName = type.name;
                valueTypePosition(i);
            }
        }
        function typePosition(type) {
            var index = null;
            for (var i=0; i<vm.codeCategory.length; i++) {
                if (vm.codeCategory[i].value === type) {
                    index = i;
                }
            }
            return index;
        }
        function valueTypePosition(index) {
            var info = vm.codeSegments[index];
            var cc = vm.codeCategory[info.typeIndex];
            if (!cc.options || cc.options.length===0) {
                BaseCodeRuleService.segmentOptions({type:cc.value},function (res) {
                    cc.options = res.data;
                    for (var i=0; i<cc.options.length; i++) {
                        var valueType = cc.options[i];
                        if (valueType.key === info.valueType) {
                            info.valueType = valueType.key;
                            info.valueTypeIndex = i;
                            info.valueTypeName = valueType.name;
                            info.valueSource = valueType.source;
                        }
                    }
                });
            } else {
                for (var i=0; i<cc.options.length; i++) {
                    var valueType = cc.options[i];
                    if (valueType.key === info.valueType) {
                        info.valueType = valueType.key;
                        info.valueTypeIndex = i;
                        info.valueTypeName = valueType.name;
                        info.valueSource = valueType.source;
                    }
                }
            }
        }
        $scope.mutualIndex = function (data,index,event){
            if (index === 0) {
                vm.codeModel.supplierId = data.id;
            } else if (index === 1) {
                vm.codeModel.ruleId = data.id;
                vm.codeModel.barcode = data.barcode;
                vm.codeModel.qrcode = data.qrcode;
                vm.codeModel.qrCodeSample = data.qrCodeSample;
                vm.codeSegments = data.segments;
                initCodeSegments();
            }
        };

        vm.saveAction = function () {
            var segements = [];

            if (!vm.codeModel.ruleId || vm.codeSegments.length === 0) {
                MessageService.error('码规则为必选项 ！');
                return
            }
            for (var i=0; i<vm.codeSegments.length; i++) {
                var seg = vm.codeSegments[i];
                if (!seg.primaryValue) {
                    MessageService.error('码段信息'+seg.name+'的初始值不能为空 ！');
                    return;
                }
                if (seg.primaryValue.length !== seg.size) {
                    console.log(seg.primaryValue,'length dost not match to:',seg.size);
                    MessageService.error('码段信息'+seg.name+'的初始值不符合位数 ！');
                    return;
                }
                var regular = new RegExp("^[" + seg.valueSource + "]+$","g");
                if (!regular.test(seg.primaryValue)) {
                    console.log(seg.valueSource,'cannot test this value:',seg.primaryValue);
                    MessageService.error('码段信息'+seg.name+'的初始值不符合组成 ！');
                    return;
                }
                segements.push({
                    startValue : seg.primaryValue,
                    seq : seg.seq,
                    baseId : seg.id,
                    name : seg.name,
                    type : seg.type,
                    valueType : seg.valueType,
                    size : seg.size,
                    description : seg.description
                });
            }
            vm.codeModel.segments = segements;

            CodeBatchService.create(vm.codeModel, function () {
                MessageService.success("创建成功!");
                $uibModalInstance.close();
            },function (error) {
                console.log('code create error',error);
                MessageService.error("创建失败"+ error.data.title||'' +"!");
            });
        };

        //计算器
        vm.productQ = null;
        vm.stackQ = null;
        vm.cal = function () {
            if (!vm.productQ || !vm.stackQ) {
                vm.resultQ = 0;
            } else {
                vm.resultQ = Math.ceil(parseInt(vm.productQ)/parseInt(vm.stackQ))
            }
            console.log(vm.productQ,vm.stackQ,vm.resultQ);
        };
        vm.resultQ = 0;
    }
})();
