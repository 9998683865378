(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('base-coderule', {
                parent: 'module',
                url: '/codeRule',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/base-manager/base-coderule/code-rule.html',
                        controller: 'CodeRuleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('base-product', {
                parent: 'module',
                url: '/baseProduct',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/base-manager/base-product/product-mgr.html',
                        controller: 'ProductMgrController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('base-sale', {
                parent: 'module',
                url: '/baseSale',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/base-manager/base-sale/sale-mgr.html',
                        controller: 'SaleMgrController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('base-supplier', {
                parent: 'module',
                url: '/baseSupplier',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/base-manager/base-supplier/supplier-mgr.html',
                        controller: 'SupplierMgrController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('base-logistics', {
                parent: 'module',
                url: '/baseLogistics',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/base-manager/base-logistics/base-logistics.html',
                        controller: 'BaseLogisticsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
