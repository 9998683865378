(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('Dictionary', Dictionary);

    Dictionary.$inject = ['$resource'];

    function Dictionary ($resource) {
        var service = $resource('qr/api/dictionary/tenant/:request', {}, {
            'searchBykey': { method: 'GET',
                params:{
                    request:"getField"
                }
            }
        });

        return service;
    }
})();
