(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('LostController', LostController);

    LostController.$inject = ['$rootScope','$scope' ,'$state', '$timeout','MessageService','CompanyAccount'];

    function LostController ($rootScope,$scope ,$state, $timeout,MessageService,CompanyAccount) {

        $scope.resetInput = true;
        var hash={
            'qq.com': 'http://mail.qq.com',
            'gmail.com': 'http://mail.google.com',
            'sina.com': 'http://mail.sina.com.cn',
            '163.com': 'http://mail.163.com',
            '126.com': 'http://mail.126.com',
            'yeah.net': 'http://www.yeah.net/',
            'sohu.com': 'http://mail.sohu.com/',
            'tom.com': 'http://mail.tom.com/',
            'sogou.com': 'http://mail.sogou.com/',
            '139.com': 'http://mail.10086.cn/',
            'hotmail.com': 'http://www.hotmail.com',
            'live.com': 'http://login.live.com/',
            'live.cn': 'http://login.live.cn/',
            'live.com.cn': 'http://login.live.com.cn',
            '189.com': 'http://webmail16.189.cn/webmail/',
            'yahoo.com.cn': 'http://mail.cn.yahoo.com/',
            'yahoo.cn': 'http://mail.cn.yahoo.com/',
            'eyou.com': 'http://www.eyou.com/',
            '21cn.com': 'http://mail.21cn.com/',
            '188.com': 'http://www.188.com/',
            'foxmail.coom': 'http://www.foxmail.com'
        };

        $scope.loginEmail = function () {
            var suffix = $scope.email.split('@')[1];
            $scope.jumpTo=hash[suffix];
            if ($scope.jumpTo == null) {
                MessageService.error("未能识别邮箱域名，请自行输入 !");
            } else {
                window.location=$scope.jumpTo;
            }
        };

        $scope.reset = function(){
            if(!$scope.name){
                MessageService.error("请输入用户名 ！");
                return;
            }
            if(!$scope.email){
                MessageService.error("请输入电子邮箱 ！");
                return;
            } else {
                var regString = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
                if (!regString.test($scope.email)) {
                    MessageService.error("请输入正确的电子邮箱格式!");
                    return;
                }
            }
            CompanyAccount.reset({
                login:$scope.name,
                email:$scope.email
            },onSuccess,onError);
        }

        function onSuccess(){
            // MessageService.success("邮件发送成功!");
            $scope.resetInput = false;
            // $scope.name = null;
            // $scope.email = null;
            // $timeout(function(){
            //     window.close();
            // },2000);
        }

        function onError(error){
            MessageService.error("重置失败，请检查用户名和邮箱，或者扫码联系MPS！");
        }
    }
})();
