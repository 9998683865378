(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PswresetController', PswresetController);

    PswresetController.$inject = ['$stateParams','$rootScope','$scope' ,'$state', '$timeout','MessageService','AccountPswReset','$interval'];

    function PswresetController ($stateParams,$rootScope,$scope ,$state, $timeout,MessageService,AccountPswReset,$interval) {
        $scope.resetInput = true;
        $scope.downCount = 3;
        $scope.newPwdEntry = true;
        $scope.confirmPwdEntry = true;
        $scope.gotoHome = function () {
            $state.go('login');
        };
        $scope.resetAction = function () {
            if (!$scope.newPassword) {
                MessageService.error('请输入密码！');
                return;
            }
            if ($scope.newPassword.length < 8) {
                MessageService.error('密码位数应大于8位！');
                return;
            }
            if ($scope.newPassword.length > 32) {
                MessageService.error('密码位数应小于32位！');
                return;
            }
            if (!$scope.confirmPassword) {
                MessageService.error('请确认密码！');
                return;
            }
            if ($scope.newPassword !== $scope.confirmPassword) {
                MessageService.error('两次密码输入不一致 !');
                return;
            }
            AccountPswReset.reset({
                newPassword:$scope.newPassword,
                key:$stateParams.resetKey
            },onSuccess,onError);

        };
        function onSuccess(){
            $scope.resetInput = false;
            var timerHandler = $interval(function () {
                if ($scope.downCount <= 0) {
                    $state.go('login');
                    $interval.cancel(timerHandler);
                } else {
                    $scope.downCount --;
                }
            },(1000));
        }

        function onError(error){
            MessageService.error("重置失败，请确认链接是否超过有效期15分钟，或者与管理员联系 ！");
        }
    }
})();
