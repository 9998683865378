(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('module', {
            abstract: true,
            parent: 'app',
            url: '/module',
            data: {
                authorities: []
            },
            views: {
                'index@': {
                    templateUrl: 'app/module/module.html',
                    controller: 'ModuleController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('module');
                    return $translate.refresh();
                }]
            }
        })
        .state('module-reset', {
            url: '/reset',
            parent: 'module',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/module/reset-password.html',
                    controller: 'ModuleResetPasswordController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    // size: 'sm',
                    resolve: {
                        entity: function () {
                            return {
                                id: null, login: null, firstName: null, lastName: null, email: null,
                                activated: true, langKey: null, createdBy: null, createdDate: null,
                                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                resetKey: null, authorities: null
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                            $translatePartialLoader.addPart('user');
                            $translatePartialLoader.addPart('user-management');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                }, function() {
                });
            }]
        });
    }
})();
