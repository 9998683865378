(function() {
    'use strict';

    angular
        .module('gwApp')
        .directive('mutualBatch', mutual)
        .directive('mutualJustSelBatch', mutualJustSel);

    mutual.$inject = ['$compile','$timeout'];
    mutualJustSel.$inject = [];

    /***
     *
     * @param mutual
     * mutual格式：  type:data:key
     * 用冒号隔开
     * type有两种 load表示带加载的下拉框, selector表示下拉框
     * data是在scope中定义的list格式数据
     * key 展示数据在data单个对象中的key 不赋值则直接取data中的元素
     * ex: controller中定义数据$scope.dataList = [{name:tomas,age:13},{name:jim,age:14}]
     * ex: 若下拉框需要选择name项，则传入数据格式为mutual = selector:dataList:name
     *
     * @param menu-style
     * 下拉框的特殊样式，mutual会将该样式中的所有 赋值给dorpdown-menu ul
     *
     * 想要写click方法，直接$scope.mutualIndex = function (data,index,event){}
     *
     * @returns mutual directive
     */

    function mutual ($compile,$timeout) {
        var directive = {
            restrict: 'A',
            scope: false,
            require: "?ngModel",
            priority: 1,
            link: linkFunc
        };

        return directive;

        function linkFunc (scope,element,attrs, $ngModel) {
            var splitArr = attrs.mutualBatch.split(':');
            var type = splitArr[0] || 'load';
            var data = splitArr[1] || 'multual-data';
            var keyw = splitArr[2];
            var dorpStyle = 'style=' + '"' + attrs.menuStyle + '"';

            if (scope.vm.mutualIndex === undefined){
                scope.vm.mutualIndex = 0;
            } else {
                scope.vm.mutualIndex++;
            }

            // console.log(scope.vm.mutualIndex);

            if (!attrs.menuStyle) {dorpStyle = ''}
            var value = attrs['ngModel'];
            var content = '<ul class="dropdown-menu" ng-if="'+data+'.length > 0" '+dorpStyle+'>\n' +
                '            <li ng-repeat="item in '+data+' track by $index"><a ng-click="'+value+' = item;mutualIndex(item,'+scope.vm.mutualIndex+',$event);mutualSel=true">{{item}}</a></li>\n' +
                '         </ul>';
            if (splitArr.length === 3) {
                content = '<ul class="dropdown-menu" ng-if="'+data+'.length > 0" '+dorpStyle+'>\n' +
                    '        <li style="font-size: 12px; padding: 0 20px; color: grey" ng-if="'+data+'.length >= 10">请输入筛选</li>\n' +
                    '        <li ng-repeat="item in '+data+' track by $index"><a ng-click="'+value+' = item[\''+keyw+'\'];mutualIndex(item,'+scope.vm.mutualIndex+',$event);mutualSel=true">{{item[\"'+keyw+'\"]}}</a></li>\n' +
                    '      </ul>';
            }
            var triangle = '<i class="triangle-inset"></i>';
            var circle = '<i class="circle-inset">\n' +
                '                <i class="circle1"></i>\n' +
                '                <i class="circle2"></i>\n' +
                '                <i class="circle3"></i>\n' +
                '         </i>';

            if (type === 'load') {
                //可选可录入
                scope.mutualOval = null;

                $compile(circle+content)(scope,function (ele) {
                    element.after(ele);
                    element.parent().css('position','relative');
                    element.attr('data-toggle','dropdown');
                    element.css('padding-right','40px');
                });
                var circleDom = element.next('.circle-inset');
                circleDom.css('display','none');

                var parentFunc = false;
                var changeFunc = scope[attrs.ngChange.split('(')[0]];
                if (!changeFunc) {
                    parentFunc =  true;
                    changeFunc = scope.$parent[attrs.ngChange.split('(')[0]];
                }
                // console.log(changeFunc);
                var stopAnimation = function () {circleDom.css('display','none')};
                var _timeout;
                var newFunc = function () {
                    var arg = arguments;
                    if(_timeout) {
                        $timeout.cancel(_timeout);
                        stopAnimation();
                    }
                    _timeout = $timeout(function() {
                        circleDom.css('display','inline-block');
                        changeFunc.apply(null,arg);
                    _timeout = null;}, 500);
                };
                scope.$watch(data,function (newVal) {
                    stopAnimation();
                });
                var firstFunc = function () {
                    var arg = arguments;
                    changeFunc.apply(null,arg);
                };

                if (parentFunc) {
                    scope.$parent[attrs.ngChange.split('(')[0]] = newFunc;
                } else {
                    scope[attrs.ngChange.split('(')[0]] = newFunc;
                }

                $timeout(function () {
                    scope.lastVal = $ngModel.$viewValue;
                });

                element.on('blur focusin click',function (e) {
                    // console.log($(this).next())
                    if (e.type === 'focusin'){
                        if (element.parent().hasClass('errorInput')) {
                            element.parent().removeClass('errorInput');
                        }
                        if (firstFunc) {
                            firstFunc();
                        }
                    }
                     else if (e.type === 'blur') {
                        if (scope.mutualJustSel) {
                            var range = scope[data].map(function (val) {
                                return val[keyw];
                            });
                            var val = element.val();
                            var i = range.indexOf(val);
                            //值检查错误,还原旧值
                            if (i === -1) {
                                val = scope.lastVal;
                            } else {
                                scope.lastVal = val;
                            }
                            element.val(val);
                            $ngModel.$setViewValue(val);
                        }
                    } else if (e.type === 'click') {
                        $(this).trigger("blur");
                    }
                });

            } else if (type === 'selector'){
                //只能选择
                $compile(triangle+content)(scope,function (ele) {
                    element.after(ele);
                    element.parent().css('position','relative');
                    element.attr('readOnly',true);
                    element.attr('data-toggle','dropdown');
                    element.css('padding-right','20px');
                });

                element.on('blur focusin click',function (e) {
                    // console.log($(this).next())
                    if (e.type === 'focusin'){
                        if (element.parent().hasClass('errorInput')) {
                            element.parent().removeClass('errorInput');
                        }
                        $(this).next().css('transform','scaleY(-1)');
                    } else if (e.type === 'blur') {
                        $(this).next().css('transform','scaleY(1)');
                    } else if (e.type === 'click') {
                        $(this).trigger("blur");
                    }
                });
            }
        }
    }

    function mutualJustSel() {
        var directive = {
            restrict: 'A',
            scope: false,
            priority: 0,
            link: linkFunc
        };

        return directive;

        function linkFunc (scope) {
            scope.mutualJustSel = true;
        }
    }
})();
