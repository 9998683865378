(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('SaleMgrController', SaleMgrController);
    SaleMgrController.$inject = ['$state', '$scope', '$uibModal', 'BaseSaleService', 'MessageService'];

    function SaleMgrController($state, $scope, $uibModal, BaseSaleService, MessageService) {
        /**
         * Created by vimpans on 2019/4/30
         */
        var vm = $scope;

        $scope.saleList = [];

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };
        initDataAction();
        function initDataAction() {
            BaseSaleService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                sort:"id,desc",
                keyword: vm.pageparams.keyword
            },function (data) {
                vm.saleList = data.data.map(function (value) {
                    value.address = value.addresses.filter(function (v) {
                        return v.primary;
                    })[0];
                    // value.contact = value.contacts.filter(function (v) {
                    //     return v.primary;
                    // })[0];
                    return value;
                });
                vm.pageparams.count = data.info.count;
            });
        }
        vm.pageChangeAction = function () {
            initDataAction();
        };
        vm.keyupAction = function (e) {
            if (e.keyCode === 13) {
                vm.searchAction();
            }
        };
        vm.searchAction = function () {
            vm.pageparams.page = 1;
            initDataAction();
        };


        $scope.statusSwitch = function (id, status, e) {
            if (status) {
                //disable action
                MessageService.confirm("确定停用该经销商？",function(){
                    BaseSaleService.disable({id:id},function () {
                        initDataAction();
                    },function (error) {
                        console.log('disable action error:',error);
                        MessageService.error('操作失败！请重试');
                        e.target.checked = true;
                    });
                },function () {
                    e.target.checked = true;
                });
            } else {
                //enable action
                BaseSaleService.enable({id:id},function () {
                    initDataAction();
                },function (error) {
                    console.log('enable action error:',error);
                    MessageService.error('操作失败！请重试');
                    e.target.checked = false;
                });
            }
        };
        $scope.createSaleModal = function () {
            $uibModal.open({
                animation: true,
                backdrop: 'static',
                keyboard: false,
                templateUrl: 'app/base-manager/base-sale/sale-create.html',
                controller: 'SaleCreateController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return null;
                    }
                }
            }).result.then(function () {
                initDataAction();
            });
        };
        $scope.editSaleAction = function (id,c) {
            $uibModal.open({
                animation: true,
                backdrop: 'static',
                keyboard: false,
                templateUrl: 'app/base-manager/base-sale/sale-create.html',
                controller: 'SaleCreateController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {id:id,check:c};
                    }
                }
            }).result.then(function () {
                initDataAction();
            });
        }
    }
})();
