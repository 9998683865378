(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ProductCreateController', ProductCreateController);
    ProductCreateController.$inject = ['$state', '$scope', '$uibModalInstance', 'Upload', 'BaseProductService', 'MessageService', 'entity', '$q'];

    function ProductCreateController($state, $scope, $uibModalInstance, Upload, BaseProductService, MessageService, entity, $q) {
        /**
         * Created by vimpans on 2019/4/30
         */
        $scope.product = entity||{};
        if (entity && entity.check) {
            $scope.forbiddenEdit = true;
        }

        $scope.uploadImage = null;
        $scope.imageUploader = function () {
            var file = document.querySelector('input[type=file]').files[0];
            resizeFile(file).then(function (res) {
                Upload.upload({
                    url: 'qr/api/files/user/upload',
                    data: {file: res || file}
                }).then(function (resp) {
                    console.log('resp',resp);
                    $scope.uploadImage = $scope.product.imageUrl = "qr/api/files?accessPath="+resp.data.data.path;
                });
            });
        };

        function resizeFile(file) {
            var deferred = $q.defer();
            try {
                var reader = new FileReader();
                reader.onload = function(e) {
                    var img = document.createElement("img");
                    img.setAttribute("crossOrigin",'Anonymous');
                    img.src = e.target.result;
                    img.onload = function (ev) {
                        var canvas = document.createElement("canvas");
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);
                        var MAX_WIDTH = 600;
                        var MAX_HEIGHT = 600;
                        var width = this.width;
                        var height = this.height;
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);
                        var dataURL = canvas.toDataURL('image/jpeg');
                        var f = dataURItoFile(dataURL, file.name);
                        deferred.resolve(f);
                    }
                };
                reader.readAsDataURL(file);
            } catch (e) {
                deferred.resolve(e);
            }
            return deferred.promise;
        }
        function dataURItoFile(dataURI, fileName) {
            var arr = dataURI.split(',');
            if (!arr[0].match(/:(.*?);/)) {return;}
            var mime = arr[0].match(/:(.*?);/)[1];
            var bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], fileName, {type:mime});
        }

        $scope.dismissModalAction = function () {
            $uibModalInstance.dismiss();
        };

        $scope.errorProductNumber = false;
        $scope.errorProductCategory = false;
        $scope.validateAction = function (t) {
            if (t === "number") {
                var regex=/^[0-9]+$/g;
                $scope.errorProductNumber = !regex.test($scope.product.number);
                $scope.nameChanging($scope.product.number);
            } else if (t === "category") {
                var regex=/^[A-Z]+$/g;
                $scope.errorProductCategory = !regex.test($scope.product.categoryNumber);
                $scope.product.rebuiltCategoryNumber = getRebuiltCategoryNumber();
            // && (!$scope.product.rebuiltCategoryNumber ||
            //         $scope.product.rebuiltCategoryNumber===undefined ||
            //         $scope.product.rebuiltCategoryNumber==="")
            }
        };
        function getRebuiltCategoryNumber() {
            if (!$scope.product.categoryNumber || $scope.product.categoryNumber==="") {
                return "";
            } else {
                return "R" + $scope.product.categoryNumber.substring(1);
            }
        }
        $scope.saveAction = function () {
            if ($scope.errorProductNumber || $scope.errorProductCategory || $scope.nameExists) {return;}

            if ($scope.product.id) {
                BaseProductService.edit($scope.product,function () {
                    MessageService.success("编辑成功!");
                    $uibModalInstance.close();
                },function (err) {
                    MessageService.error("编辑失败，请检查输入!");
                });
            } else {
                BaseProductService.create($scope.product,function () {
                    MessageService.success("创建成功!");
                    $uibModalInstance.close();
                },function (err) {
                    MessageService.error("创建失败，请检查输入!");
                });
            }
        };

        $scope.timeout = null;
        $scope.nameChanging = function (name) {
            $scope.nameExists = false;
            if ($scope.timeout) {
                clearTimeout($scope.timeout);
            }
            $scope.timeout = setTimeout(function () {
                checkField(name);
            },1500);
        };
        function checkField(name) {
            if (!name) {
                return;
            }
            var param = {
                name:'number',
                value: name,
                ref: $scope.product.id
            };
            BaseProductService.checkField(param, function (res) {
                $scope.nameExists = res.data.exists;
            });
        }
    }
})();
