(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('ModuleController', ModuleController);

    ModuleController.$inject = ['$http','$scope','$rootScope', 'Principal', '$window','Auth', 'LoginService', '$state','Module','$localStorage','$sessionStorage','SideBarService', 'SmartDocService', '$q'];

    function ModuleController ($http,$scope,$rootScope, Principal, $window,Auth, LoginService, $state,Module,$localStorage,$sessionStorage,SideBarService, SmartDocService, $q) {
        var vm = this;
        vm.modules = Module;
        vm.hide = hide;
        vm.check = check;
        vm.logout = logout;

        vm.change = change;
        vm.sort = sort;

        init();

        function init(){

        }

        SideBarService.sort();

        function sort(data){
            if(data.imageUrl==null||data.imageUrl==""){
                vm.hasImg = false;
            }else{
                vm.hasImg = true;
            }
            vm.userImgUrl=data.imageUrl;
        }

        function change(){
            $window.location.reload();
        }

        function hide(id){
            //获取左侧菜单下的全部li元素。移除高亮样式
            //由于是循环，就制定id，返回元素，加高亮样式
            $("#sidebar").find("li").removeClass("dcjq-parent active");
            $("#"+id).parent().addClass("dcjq-parent active");

            var wSize = $(window).width();
            if(wSize<768){
                vm.click = true;
            }
            if(id=='helpIn'){
                var url = $rootScope.global.url+"/admin/#/help";
                window.open(url);
                // window.open("http://localhost:8081/#/help");
            }
            window.scrollTo(0,0);
        }

        function check(){
            const $nav = $(".ml-nav");
            const $container = $("#container");
            var $icon = $("#check-icon");
            $container.toggleClass('container-pc');
            if($container.hasClass("container-pc")){
                $nav.attr("class", "ml-nav ml-nav-move")
                $(".ml-nav").find(".ml-menu-parent").unbind();
                $icon.removeClass("fa-outdent");
                $icon.addClass("fa-indent");
            }else {
                $icon.removeClass("fa-indent");
                $icon.addClass("fa-outdent");
                $nav.attr("class", "ml-nav ml-nav-pc")
                menu()
            }
        }

        function logout(){
            $state.go("login");
            Auth.logout();
        }

        $scope.$on('changeUserLogo', function(e,m) {
            vm.userImgUrl = "api/fileUpload/img?url="+m;
        });

        // role change
        $scope.$on('changeRoleName', function(e, m) {
            vm.roleName = m;
        });

        $q.all({
            doc: SmartDocService.getAll({docType:"CodeBatch"}).$promise,
            identity: Principal.identity()
        }).then(function (res) {
            var menus = vm.modules[1].menus || [];
            menus = menus.filter(function(m) {
                return !m.flag;
            });
            var docs = res.doc.data || [];
            for (var d=0; d<Math.min(docs.length, 5); d++) {
                var docItem = docs[d];
                var docMenu = {
                    auth: ["ROLE_CODE_QUERY", "ROLE_CODE_VIEW"],
                    icon: "fa fa-user",
                    uiSref: "code({id:"+docItem.id+"})",
                    id: "code"+docItem.id,
                    internalUnit: true,
                    name: docItem.name,
                    visiable: true,
                    flag: true
                };
                menus.push(docMenu);
            }
            vm.modules[1].menus = menus;

            var data = res.identity.data;
            vm.userName = data.fullname;
            vm.login = data.login;
            vm.roleName = data.roleName;
            vm.auth = data.authorities;
            sort(data);
            if(vm.auth.length > 0 && vm.modules.length > 0) {
                for(var i = 0; i < vm.modules.length; i++) {
                    vm.modules[i].visiable = false;
                    for(var n = 0; n < vm.auth.length; n++) {
                        if(vm.modules[i].auth.length > 0 && vm.modules[i].auth.indexOf(vm.auth[n]) > -1) {
                            vm.modules[i].visiable = true;
                            for(var l = 0; l < vm.modules[i].menus.length; l++) {
                                vm.modules[i].menus[l].visiable = false;
                                for(var m = 0; m < vm.auth.length; m++) {
                                    if(vm.modules[i].menus[l].auth.length > 0 && vm.modules[i].menus[l].auth.indexOf(vm.auth[m]) > -1) {
                                        vm.modules[i].menus[l].visiable = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            setTimeout(function () {
                menu()
            });
        });

        // Principal.identity().then(function(data) {
        //     sort(data.data);
        //     vm.userName = data.data.fullname;
        //     vm.login = data.data.login;
        //     vm.roleName = data.data.roleName;
        //     vm.auth=data.data.authorities;
        //     if(vm.auth.length > 0 && vm.modules.length > 0) {
        //         for(var i = 0; i < vm.modules.length; i++) {
        //             vm.modules[i].visiable = false;
        //             for(var n = 0; n < vm.auth.length; n++) {
        //                 if(vm.modules[i].auth.length > 0 && vm.modules[i].auth.indexOf(vm.auth[n]) > -1) {
        //                     vm.modules[i].visiable = true;
        //                     for(var l = 0; l < vm.modules[i].menus.length; l++) {
        //                         vm.modules[i].menus[l].visiable = false;
        //                         for(var m = 0; m < vm.auth.length; m++) {
        //                             if(vm.modules[i].menus[l].auth.length > 0 && vm.modules[i].menus[l].auth.indexOf(vm.auth[m]) > -1) {
        //                                 vm.modules[i].menus[l].visiable = true;
        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // });

    }
    function menu() {
        //菜单
        // const $url = window.location.href;
        const $thisUrl = window.location.hash;
        $(".ml-nav").find(".ml-menu-ul").each(function () {
            var _this = this;
            $(this).find(".ml-menu-son li").each(function () {
                var $href = $(this).children("a").attr("href");
                if($thisUrl === $href){
                    $(_this).addClass("ml-menu-pitchOn");
                    $(this).addClass("ml-menu-son-pitchOn");
                    $(".ml-nav").find(".ml-menu-son").hide();
                    setTimeout(function () {
                        $(_this).find(".ml-menu-son").slideDown();
                    },200);
                    $(".ml-menu-parent-text-icon").children("i").attr("class", "fa fa-angle-up");
                    $(_this).find(".ml-menu-parent-text-icon").children("i").attr("class", "fa fa-angle-down")
                }
            });
            $(this).find(".ml-menu-parent").on("click", function () {
                var that = this;
                $(_this).siblings().children(".ml-menu-son").slideUp();
                $(_this).children(".ml-menu-son").slideToggle(function () {
                    $(".ml-menu-parent-text-icon").children("i").attr("class", "fa fa-angle-up");
                    if($(this).is(":hidden")){
                        $(that).find(".ml-menu-parent-text-icon").children("i").attr("class", "fa fa-angle-up")
                    }else {
                        $(that).find(".ml-menu-parent-text-icon").children("i").attr("class", "fa fa-angle-down")
                    }
                });
            });
            $(this).find(".ml-menu-son li").on("click", function () {
                $(_this).addClass("ml-menu-pitchOn").siblings().removeClass("ml-menu-pitchOn");
                $(".ml-nav").find("li").removeClass("ml-menu-son-pitchOn");
                $(this).addClass("ml-menu-son-pitchOn");
            })
        })
    }

})();
